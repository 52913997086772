<template>
    <Tree :data="dataTree"></Tree>
    <Modal
        v-model="setModule"
        title="模型选择"
        @on-ok="submitSetModel"
        @on-cancel="cancel"
        :closable="false"
        :mask-closable="false"
        draggable
        sticky
        width="700"
        class-name="vertical-center-modal"
    >
        <Form :model="form" :label-width="120" style="width: 400px">
            <FormItem label="虚拟喷嘴模型">
                <Select v-model="form.nozzleModel">
                    <Option value="yuce">Yuceil/Otugen</Option>
                    <Option value="birc">Birch</Option>
                    <Option value="bir2">Birch2</Option>
                    <Option value="ewan">Ewan/Moodie</Option>
                    <Option value="molk">Molkov</Option>
                </Select>
            </FormItem>
            <FormItem label="流体相态">
                <Select v-model="form.fluid_phase">
                    <Option value="none">气体</Option>
                    <Option value="gas">饱和气体</Option>
                    <Option value="liquid">饱和液体</Option>
                </Select>
            </FormItem></Form
        >
    </Modal>
    <Modal
        v-model="output"
        title="输出设置"
        @on-ok="submitOutput"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form :model="form" :label-width="130" style="width: 400px">
            <FormItem label="输出指定时刻压力">
                <Input
                    v-model="contours"
                    placeholder="请输入指定点压力值，以空格隔开"
                    @on-change="changeContours"
                >
                    <template #append> s </template>
                </Input>
            </FormItem>
            <FormItem label="最大时间">
                <Input v-model="form.timeMax" placeholder="请输入最大时间">
                    <template #append>s</template></Input
                >
            </FormItem></Form
        >
    </Modal>
    <Modal
        v-model="environmentParam"
        title="环境参数"
        @on-ok="submitEnvironmentParam"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form
            :model="form"
            label-position="right"
            :label-width="150"
            style="width: 400px"
        >
            <FormItem label="环境温度">
                <Input v-model="form.amb_temperature" type="number">
                    <template #append> K</template></Input
                >
            </FormItem>
            <FormItem label="环境压力" type="number">
                <Input v-model="form.amb_pressure">
                    <template #append>Pa</template></Input
                >
            </FormItem>
            <FormItem label="空间高度" type="number">
                <Input v-model="form.enclosureHeight">
                    <template #append>m</template></Input
                >
            </FormItem>
            <FormItem label="空间上下表面面积">
                <Input v-model="form.floorCeilArea" type="number">
                    <template #append>m<sup>2</sup></template></Input
                >
            </FormItem>
            <FormItem label="通风流量" type="number">
                <Input v-model="form.ventVolumeFlowRate">
                    <template #append>m<sup>3</sup>/s</template></Input
                >
            </FormItem>
            <FormItem label="上通风口面积" type="number">
                <Input v-model="form.ceilVent_Area">
                    <template #append>m<sup>2</sup></template></Input
                >
            </FormItem>
            <FormItem label="上通风口高度">
                <Input v-model="form.ceilVent_Height" type="number">
                    <template #append>m</template></Input
                >
            </FormItem>
            <FormItem label="下通风口面积" type="number">
                <Input v-model="form.floorVent_Area"
                    ><template #append>m<sup>2</sup></template>
                </Input>
            </FormItem>
            <FormItem label="下通风口高度">
                <Input v-model="form.floorVent_Height" type="number">
                    <template #append>m</template></Input
                >
            </FormItem>
        </Form>
    </Modal>
    <Modal
        v-model="capacityParam"
        title="容器参数"
        @on-ok="submitCapacityParam"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form
            :model="form"
            label-position="right"
            :label-width="140"
            style="width: 400px"
        >
            <FormItem label="泄漏直径">
                <Input v-model="form.orificeDiameter" type="number">
                    <template #append>m</template></Input
                >
            </FormItem>
            <FormItem label="泄放系数" type="number">
                <Input v-model="form.orificeDischargeCoefficient"> </Input>
            </FormItem>
            <FormItem label="泄漏角度" type="number">
                <Input
                    v-model="form.orificeAngleTemp"
                    @on-change="changeOrificeAngleTemp"
                    ><template #append>°</template>
                </Input>
            </FormItem>
            <FormItem label="流体压力" type="number">
                <Input v-model="form.fluid_pressure">
                    <template #append>Pa</template></Input
                >
            </FormItem>
            <FormItem
                label="流体温度"
                v-if="form.fluid_phase === 'none'"
                type="number"
            >
                <Input v-model="form.fluid_temperature"
                    ><template #append>K</template>
                </Input>
            </FormItem>
            <FormItem label="泄漏高度" type="number">
                <Input v-model="form.orificeHeight">
                    <template #append>m</template></Input
                >
            </FormItem>
            <FormItem label="泄漏点与墙体距离" type="number">
                <Input v-model="form.wallDistance"
                    ><template #append>m</template>
                </Input>
            </FormItem>
            <FormItem label="容器体积" type="number">
                <Input v-model="form.tank_volume"
                    ><template #append>m<sup>3</sup></template>
                </Input>
            </FormItem>
        </Form>
    </Modal>
</template>
<script>
import global from "../assets/global.vue";
import { resolveComponent } from "vue";
export default {
    name: "accumulationTree",
    data() {
        return {
            contours:
                "1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29",
            ////////
            setModule: false,
            output: false,
            environmentParam: false,
            capacityParam: false,
            ////////
            form: { ...global.accumulationJSON, orificeAngleTemp: 0 },
            dataTree: [
                {
                    title: "模型选择",
                    expand: true,
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.setModule = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "logo-codepen",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                },
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.output = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "ios-create",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "输出设置",
                    expand: true,
                },
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.environmentParam = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "ios-compass",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "环境参数",
                    expand: true,
                },
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.capacityParam = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "md-battery-dead",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "容器参数",
                    expand: true,
                },
            ],
        };
    },
    created() {
        global.accumulationTempJSON = this.form;
    },
    watch: {
        form: {
            handler(newVal, oldVal) {
                global.accumulationTempJSON = newVal;
            },
        },
    },
    methods: {
        cancel(){
            console.log(0)
            if(global.accumulationTempJSON!=global.accumulationJSON){
                console.log(1)
            }
        },
        changeOrificeAngleTemp() {
            this.form.orificeAngle =
                (this.form.orificeAngleTemp * Math.PI) / 180;
        },
        submitSetModel() {
            this.setModule = false;
        },
        submitCapacityParam() {
            this.capacityParam = false;
        },
        submitOutput() {
            //处理contours
            let arr = this.contours;
            let arr_string = arr.split(" ");
            for (let i = 0; i < arr_string.length; i++) {
                arr_string[i] = parseFloat(arr_string[i]);
            }
            this.form.times = arr_string;
            this.output = false;
        },
        changeContours() {
            let arr = this.contours;
            let arr_string = arr.split(" ");
            for (let i = 0; i < arr_string.length; i++) {
                arr_string[i] = parseFloat(arr_string[i]);
            }
            this.form.times = arr_string;
        },
        submitEnvironmentParam() {
            this.environmentParam = false;
        },
    },
};
</script>
<style scoped>
::v-deep .ivu-input-group-append {
    width: 80px;
}
</style>