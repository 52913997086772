<template>
    <div class="main">
    <HomeBtn/>
    <div class="login-main">
        <div class="logo">
            <div class="logo-all">
                <img
                    src="../../assets/register_logo.jpg"
                    alt=""
                    class="logo-img"
                />
            </div>
        </div>

        <el-input
            v-model="phoneNum"
            type="text"
            placeholder="请输入账号"
            class="input-box"
            size="large"
        >
        </el-input>

        <el-input
            v-model="password"
            type="password"
            placeholder="请输入密码"
            show-password
            class="input-box"
            size="large"
        />
        <div class="input-box">
            <span class="token"><strong>密钥：</strong>{{ token }}</span>
            <Button type="text" style="float: right" @click="changeToken()">修改密钥</Button>
        </div>
        <el-row>
            <el-col :span="12">
                <div class="forget-password" @click="toLogin">
                    已有账户？点击登录
                </div></el-col
            >
            <el-col :span="12"> </el-col>
        </el-row>

        <Button
            size="large"
            type="primary"
            class="login-button"
            @click="login()"
            >注 册</Button
        >
        <div class="check-box">
            <el-checkbox
                style="float: left"
                v-model="privateCheck"
                label=" "
                size="large"
            />
            <div class="check-text">
                我已阅读并同意C-SAT服务协议和隐私政策
            </div>
        </div>
    </div>
    <h3 class="title">面向 “双碳” 立足氢能安全</h3>
    <h3 class="title title2">做氢能QRA领域第一品牌</h3>
    </div>
    <el-dialog
        v-model="tokenVisible"
        title="产品密钥｜token令牌"
        :show-close="false"
    >
        <el-form :model="tokenForm">
            <el-form-item label="密钥" :label-width="formLabelWidth">
                <el-input
                    v-model="tokenForm.token"
                    autocomplete="off"
                    placeholder="请输入您的产品密钥"
                />
            </el-form-item>
        </el-form>
        <Button type="text" @click="getToken">没有密钥？点我获取</Button>
        <template #footer>
            <span class="dialog-footer">
                <Button type="text" @click="cancelToken()"
                    >取消</Button
                >
                <Button type="primary" @click="confirmToken()"> 确认 </Button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
import HomeBtn from "@/components/HomeBtn.vue";
import http from "../../assets/axiosInstance";
export default {
  components:{HomeBtn},
    data() {
        return {
            phoneNum: "",
            password: "",
            privateCheck: true,
            rememberMe: true,
            tokenForm: {
                token: "",
            },
            token: "",
            tokenVisible: true,
        };
    },
    methods: {
        changeToken() {
            this.tokenVisible = true;
        },
        cancelToken() {
            this.token = '未填写密钥';
            this.tokenVisible = false;
        },
        confirmToken() {
            console.log(this.tokenForm.token);
            if (this.tokenForm.token == "") {
                this.token = "未填写密钥";
                this.tokenVisible = false;
            }
            else {
                this.token = this.tokenForm.token;
                this.tokenVisible = false;
            }
        },
        getToken() {
            alert("获取密钥");
        },
        toLogin() {
            this.$router.push("/login");
        },
        async login() {
            let loginForm = {
                username: this.phoneNum,
                password: this.password,
                productKey: this.token,
            };
            http({
                url: "/register",
                method: "post",
                data: loginForm,
            })
                .then((res) => {
                console.log(res);
                if (res.data.register == -1) {
                    this.$Message["error"]({
                        background: true,
                        content: "密钥已全部分配，无法注册",
                    });
                }
                if (res.data.register == -2) {
                    this.$Message["error"]({
                        background: true,
                        content: "密钥错误，请核对",
                    });
                }
                if (res.data.register == -3) {
                    this.$Message["error"]({
                        background: true,
                        content: "密钥错误，请核对",
                    });
                }
                if (res.data.register >= 0) {
                    this.$Message["success"]({
                        background: true,
                        content: "注册成功，3秒后跳转登录页",
                    });
                    localStorage.setItem('token', this.token);
                    setTimeout(() => {
                        this.$router.push("/login");
                    }, 3000);
                }
            })
                .catch(function (error) { });
        },
    },
    components: { HomeBtn }
};
</script>
<style lang="scss" scoped>
.divider {
    width: 320px;
    margin: 100px 40px 30px 40px;
}
.main {
    width: 100%;
    height: 100%;
    background-color: white;
    position: fixed;
    background: url(../../assets/jsconfig.png) 0% 0% / 100% 100%;
}
.title {
        position: absolute;
        font-size: 32px;
        color: #fff;
        margin: 0;
        left: calc(50% - 300px);
        top: calc(50% + 210px);
        &.title2 {
            left: calc(50% - 120px);
            top: calc(50% + 260px);
        }
    }
.login-main {
    position: absolute;
    width: 400px;
    height: 500px;
    border-radius: 20px;
    top:calc( 50% - 100px);
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: var(--el-box-shadow-dark);
    background: #fff;
}
.input-box {
    margin: 0 40px 15px 40px;
    width: 320px;
}
.logo {
    width: 100%;
    height: 150px;
    position: relative;
}
.logo-img {
    width: 320px;
    float: left;
    margin: 40px 0 40px 40px;
}

.forget-password {
    width: 140px;
    color: #909399;
    font-size: 14px;
    margin: 0 0 20px 40px;
    transition: all 0.3s;
    cursor: pointer;
}
.forget-password:hover {
    color: #303133;
}
.login-button {
    width: 320px;
    margin: 15px 40px 0 40px;
}
.check-box {
    margin: 5px 40px 0 40px;
}
.check-text {
    line-height: 40px;
    font-size: 12px;
    float: left;
}
.remember-me-check {
    margin: 0 40px 0 0;
    float: right;
    transform: translate(0, -10px);
}
.remember-me {
    font-size: 14px;
}
.token{
    line-height: 32px;
}
</style>