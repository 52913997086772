<template>
    <Tree :data="dataTree"></Tree>
    <Modal
        v-model="setModule"
        title="模型选择"
        @on-ok="submitSetModel"
        :mask-closable="false"
        draggable
        sticky
        width="700"
        class-name="vertical-center-modal"
    >
        <Form :model="form" :label-width="100" style="width: 100%">
            <FormItem label="虚拟喷嘴模型">
                <Select v-model="form.nozzleModel">
                    <Option value="yuce">Yuceil/Otugen</Option>
                    <Option value="birc">Birch</Option>
                    <Option value="bir2">Birch2</Option>
                    <Option value="ewan">Ewan/Moodie</Option>
                    <Option value="molk">Molkov</Option>
                </Select>
            </FormItem>
            <FormItem label="流体相态">
                <Select v-model="form.fluidPhase">
                    <Option value="none">气体</Option>
                    <Option value="gas">饱和气体</Option>
                    <Option value="liquid">饱和液体</Option>
                </Select>
            </FormItem></Form
        >
    </Modal>
    <Modal
        v-model="output"
        title="输出设置"
        @on-ok="submitOutput"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form :model="form" :label-width="150" style="width: 100%">
            <FormItem label="X 坐标最小值">
                <Input v-model="form.x_min" type="number">
                    <template #append> m </template></Input
                >
            </FormItem>
            <FormItem label="X 坐标最大值" type="number">
                <Input v-model="form.x_max">
                    <template #append> m </template></Input
                >
            </FormItem>
            <FormItem label="Y 坐标最小值">
                <Input v-model="form.y_min" type="number">
                    <template #append> m </template></Input
                >
            </FormItem>
            <FormItem label="Y 坐标最大值" type="number">
                <Input v-model="form.y_max">
                    <template #append> m </template></Input
                >
            </FormItem>
            <FormItem label="等值轮廓线摩尔分数" type="number">
                <Input v-model="form.contours"><template #append>mol/mol</template> </Input> </FormItem
        >
        <Table border size="small" style="margin-bottom:16px" :columns="columnsX" :data="form.points">
            <template #x="{ row, index }">
              <Input v-model="row.x" @change="changeRow(row.x, 'x', index)" />
            </template>
            <template #y="{ row, index }">
              <Input v-model="row.y" @change="changeRow(row.y, 'y', index)" />
            </template>
            <template #o="{ row, index }">
              <Button type="success" @click="addRow">+</Button>
              <Button type="danger" @click="delRow(index)" :disabled="form.points.length===1" style="margin-left:16px">-</Button>
            </template>
          </Table>
        </Form>
    </Modal>
    <Modal
        v-model="environmentParam"
        title="环境参数"
        @on-ok="submitEnvironmentParam"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form
            :model="form"
            label-position="right"
            :label-width="100"
            style="width: 400px"
        >
            <FormItem label="环境温度">
                <Input v-model="form.amb_temperature" type="number">
                    <template #append> K </template></Input
                >
            </FormItem>
            <FormItem label="环境压力" type="number">
                <Input v-model="form.amb_pressure">
                    <template #append> Pa </template></Input
                >
            </FormItem>
        </Form>
    </Modal>
    <Modal
        v-model="capacityParam"
        title="容器参数"
        @on-ok="submitCapacityParam"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form
            :model="form"
            label-position="right"
            :label-width="100"
            style="width: 400px"
        > 
            <FormItem label="泄漏直径">
                <Input v-model="form.orificeDiameter" type="number">
                    <template #append> m </template></Input
                >
            </FormItem>
            <FormItem label="泄放系数" type="number">
                <Input v-model="form.orificeDischargeCoefficient"> </Input>
            </FormItem>
            <FormItem label="喷射角度" type="number">
                <Input v-model="form.angleTemp"  @change="changeAngleTemp"
                    ><template #append>°</template>
                </Input>
            </FormItem>
            <FormItem label="流体压力" type="number">
                <Input v-model="form.fluid_pressure">
                    <template #append> Pa </template></Input
                >
            </FormItem>
            <FormItem label="流体温度" v-if="form.fluidPhase==='none'" type="number">
                <Input v-model="form.fluid_temperature"
                    ><template #append> K </template>
                </Input>
            </FormItem>
        </Form>
    </Modal>
</template>
<script>
import global from "../assets/global.vue";
import { resolveComponent } from "vue";
export default {
    name: "plumeTree",
    data() {
        return {
          columnsX:[
            {
              title: "X 坐标 (m)",
              key: "x",
              align: 'center',
              slot: 'x'
            },
            {
              title: "Y 坐标 (m)",
              key: "y",
              align: 'center',
              slot: 'y'
            },
            {
              title: "操作",
              key: "o",
              align: 'center',
              slot: 'o'
            },
          ],
            ////////
            setModule: false,
            output: false,
            environmentParam: false,
            capacityParam: false,
            ////////
            form: {...global.plumeJSON,angleTemp:90,points:[
              {x:0,y:1},
              {x:0,y:2},
              {x:0,y:3},
              {x:0,y:4},
              {x:0,y:5},
            ]},
            dataTree: [
                {
                    title: "模型选择",
                    expand: true,
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick:()=>this.setModule=true
                            },
                            
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "logo-codepen",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                },
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => this.output = true,
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "ios-create",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "输出设置",
                    expand: true,
                },
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick:() =>this.environmentParam = true,
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "ios-compass",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "环境参数",
                    expand: true,
                },
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick:() =>this.capacityParam = true,
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "md-battery-dead",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "容器参数",
                    expand: true,
                },
            ],
        };
    },
  created() {
    global.plumeTempJSON = this.form;
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        global.plumeTempJSON = newVal;
        console.log(this.form,'ccc')
      },
      deep:true
    },
  },
    methods: {
      addRow(){
        this.form.points.push({x:0,y:0})
      },
      delRow(index){
        this.form.points.splice(index,1)
      },
      changeRow(val, key, index) {
        this.form.points[index][key] = Number(val)
      },
      changeAngleTemp(){
        this.form.angle=this.form.angleTemp*Math.PI/180
      },
        submitSetModel() {
            this.setModule = false;
        },
        submitCapacityParam() {
        
            this.capacityParam = false;
        },
        submitOutput() {
            this.output = false;
        },
        submitEnvironmentParam() {
          
            this.environmentParam = false;
            // this.environmentParam = false;
        },
    },
};
</script>
<style scoped>
</style>