import router from '../router'

import http from "../assets/axiosInstance";

import { ElMessage } from 'element-plus'


export const checkToken=()=>{
  const token=localStorage.getItem('token')
  const username=localStorage.getItem('name')
  if(!token||!username){
    router.push('/login')
  }else{
    http({
      url: "/checkToken",
      method: "post",
      data: {
          token,
          username
      },
  })
      .then((res) => {
          if(!res.data.available){
              localStorage.clear()
              ElMessage.error('当前登录已过期，请重新登录')
              router.push('/login')
          }else{
            setTimeout(()=>{
              checkToken()
            },60000)
          }
      })
  }
}

