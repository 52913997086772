<template>
    <Layout style="height: 100%">
        <Sider class="sider-tree" hide-trigger>
            <overpressureTree ref="treeRef"></overpressureTree>
        </Sider>
        <Layout>
            <Header class="tool-bar">
                <Button type="success" @click="run()" shape="circle">
                    <svg class="icon" aria-hidden="true" style="width: 20px">
                        <use xlink:href="#icon-run-solid"></use>
                    </svg>
                    运行
                </Button>
                <template v-if="gis.enable">
                  <ImgUpload ref="imgUploadRef" @uploadSuccess="uploadSuccess"/>
                  <Button
                    style="margin-left:16px"
                      @click="chosePoint=true;$Message.success('可标记泄漏位置');"
                      type="warning"
                      shape="circle"
                  >
                      <svg class="icon" aria-hidden="true" style="width: 20px">
                          <use xlink:href="#icon-daoru"></use>
                      </svg>
                      标记位置
                  </Button>
                </template>
                <Button type="success" @click="changeEnalbe()" shape="circle" style="margin-top:20px;float:right"
                    >{{gis.enable?'关闭':'启用'}}GIS</Button>
                <FooterMsg></FooterMsg>
            </Header>
            <Content class="content" style="transform: translateX(0)">
              <Modal
                    :transfer="false"
                    :footer-hide="true"
                    :fullscreen="true"
                    v-model="viewVisible"
                    title="运行结果"
                >
                <Tabs value="name1" style="height: 100%;">
                    <TabPane label="爆炸超压三视图" name="name0">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="imgSRC"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                    <TabPane label="超压数据" name="name5">
                        <div class="data" v-if="overpressureData.length">
                            <Table
                            align="center"
                            border
                            :columns="overpressureCol"
                            :data="overpressureData"
                        ></Table>
                          
                        </div>
                        <Image width="60%" v-else :src="require('@/assets/default-bg.png')" style="display:block;margin:auto"/>
                        
                    </TabPane>
                    <TabPane label="GIS" v-if="gis.enable" name="name3">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="gisImagePath"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                </Tabs>
                </Modal>
                <!-- <Tabs v-if="runed" value="name1">
                    <TabPane label="爆炸超压三视图" name="name0">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="imgSRC"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                    <TabPane label="超压数据" name="name5">
                        <div class="data" v-if="overpressureData.length">
                            <Table
                            align="center"
                            border
                            :columns="overpressureCol"
                            :data="overpressureData"
                        ></Table>
                          
                        </div>
                        <Image width="60%" v-else src="@/assets/default-bg.png" style="display:block;margin:auto"/>
                        
                    </TabPane>
                    <TabPane label="GIS" v-if="gis.enable" name="name3">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="gisImagePath"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                </Tabs> -->
                <template v-if="!viewVisible">
                  <div v-if="gis.imageurl" ref="gisBox" class="gis-box" @click="gisClick">
                    <img ref="gisImgRef" :src="gis.imageurl" class="gis-img">
                    <div :style="pointStyle" class="gis-po"></div>
                  </div>
                  <Image v-else width="60%"  :src="require('@/assets/default-bg.png')" style="display:block;left: 50%;top: 50%;transform: translate(-50%, -50%);"/>
                </template>    
            </Content>
        </Layout>
    </Layout>
    <Spin size="large" fix :show="spinShow"></Spin>
</template>
<script>
import FooterMsg from "../components/footer.vue";
import overpressureTree from "../components/overpressureComponent.vue";
import global from "../assets/global.vue";
import http from "../assets/axiosInstance";
import ImgUpload from "../components/imgUpload.vue"
export default {
    data() {
        return {
          runed:false,
            spinShow: false, //加载
            response: null,
            imgWidth: "60%",
            imgHeight: "auto",
            overpressureCol: [
                {
                    title: "x (m)",
                    key: "x",
                },
                {
                    title: "y (m)",
                    key: "y",
                },
                {
                    title: "z (m)",
                    key: "z",
                },
                {
                    title: "冲量 (Pa·m)",
                    key: "impulse",
                },
                {
                    title: "超压值 (Pa)",
                    key: "overpressure",
                },
            ],
            overpressureData: [],
            imgSRC: require('@/assets/default-bg.png'),
            enable:false,//是否启用gis
            gis:{
              enable:false,
              imageurl:'',
              meterPerPixel:0,
              centerX:0,
              centerY:0,
              rotation:0,
            },
            chosePoint:false,
            pointStyle:{
              left:0,
              top:0,
            },
            gisImagePath:'',
            viewVisible:false
        };
    },
    created(){
        // http({
        //         url: "/checkToken",
        //         method: "post",
        //         data: {
        //             token:localStorage.getItem('token'),
        //             username:localStorage.getItem('name')
        //         },
        //     })
        //         .then((res) => {
        //             if(!res.data.available){
        //                 localStorage.clear()
        //                 this.$router.push('/login')
        //             }
        //         })
        //         .catch(function (error) {});
    },
    components: { overpressureTree, FooterMsg ,ImgUpload},
    methods: {
      gisClick(e){
          if(this.chosePoint){
            const {layerX,layerY}=e
            const y=(layerY/this.$refs.gisBox.offsetHeight)
            const x= (layerX/this.$refs.gisBox.offsetWidth)
            this.pointStyle={
              left:x*100+'%',
              top:y*100+'100%'
            }
            const {naturalWidth,naturalHeight}=this.$refs.gisImgRef
            this.gis.centerX=naturalWidth*x
            this.gis.centerY=naturalHeight*y
            this.chosePoint=false
          }
        },
        changeEnalbe(){
          this.gis.enable=!this.gis.enable
          this.gis.imageurl='',
          this.gis.meterPerPixel=0
          this.gis.centerX=0
          this.gis.centerY=0
          this.gis.rotation=0
          if(this.gis.enable){
            this.viewVisible=false
            this.img_flux_plot_filepath=require('@/assets/default-bg.png')
            this.img_temp_plot_filepath=require('@/assets/default-bg.png')
            this.runed=false
            this.$nextTick(()=>{
              this.$refs.imgUploadRef.init()
            })
          }
        },
        uploadSuccess(data){
          this.viewVisible=false
          const {src:imageurl,rotate:rotation,meterPerPixel}=data
          Object.assign(this.gis,{imageurl,rotation,meterPerPixel})
        },
        dataCheck(obj) {
            let keyNameArr = Object.keys(obj);

            for (let i = 0; i < keyNameArr.length; i++) {
                if (
                    keyNameArr[i] == "fluid_phase" ||
                    keyNameArr[i] == "nozzleModel" ||
                    keyNameArr[i] == "method" ||
                    keyNameArr[i] == "XPOS" ||
                    keyNameArr[i] == "YPOS" ||
                    keyNameArr[i] == "ZPOS" ||
                    keyNameArr[i] == "contours"
                ) {
                    console.log(
                        "爆炸超压无需修正数据",
                        keyNameArr[i],
                        typeof obj[keyNameArr[i]]
                    );
                    continue;
                }
                obj[keyNameArr[i]] = parseFloat(obj[keyNameArr[i]]);
                console.log(
                    "爆炸超压数据修正",
                    keyNameArr[i],
                    typeof obj[keyNameArr[i]]
                );
            }
        },
        async run() {
          if(this.gis.enable&&!this.gis.imageurl){
            this.$message({
              message:'请先上传gis地图',
              showClose:true,
              type:'error',
              duration:0
            })
            return false
          }
          const {fluid_phase,fluid_pressure}=this.$refs.treeRef.form
          if(['gas','liquid'].includes(fluid_phase)&&(Number(fluid_pressure)>1296400||Number(fluid_pressure)<101325)){
               this.$message({
                message:'流体压力输入值应在101325Pa——1296400Pa之间',
                showClose:true,
                type:'error',
                duration:0
              })
              this.$nextTick(()=>{
                this.capacityParam=true
              })
              return false
            }
            this.dataCheck(global.overpressureTempJSON);
            this.spinShow = true;
            //console.log("运行参数pll", global.pllTempJSON);
            http({
                url: "/c_sat/ppc/overpressure",
                method: "post",
                data: {...global.overpressureTempJSON,gis:this.gis},
            })
                .then((res) => {
                    if (res.data) {
                      this.runed=true
                      this.overpressureData=[]
                        this.$Notice.success({
                            title: "爆炸超压计算成功！",
                        });
                        this.imgWidth = "80%";
                        this.imgSRC =
                            "/api" +
                            res.data.figure_file_path;
                            this.gisImagePath =
                            "/api" +
                            res.data.gis_plot_filepath;
                            // console.log(this.gisImagePath,'ccc')
                        this.response = res;
                        console.log(typeof res.data,' res.data.impulse');
                        for (let i = 0; i < res.data.impulse.length; i++) {
                            let temp = {
                                impulse: null,
                                overpressure: null,
                            };
                            let value = res.data.impulse[i];
                            let p = Math.floor(Math.log(value) / Math.LN10);
                            let n = (value * Math.pow(10, -p)).toFixed(1);
                            temp.impulse=n + 'e' + p
                            temp.overpressure = res.data.overpressure[i].toFixed(2);
                            const tableData=this.$refs.treeRef.dataX
                            temp.x = tableData[i].x
                            temp.y =tableData[i].y
                            temp.z = tableData[i].z
                            this.overpressureData.push(temp);
                        }
                        this.spinShow = false;
                        this.viewVisible=true
                    }
                    if (!res.data) {
                        this.$Notice.error({
                            title: "错误",
                            desc: "计算失败，请检查后端是否返回数据",
                        });
                        this.spinShow = false;
                    }
                })
                .catch(function (error) {
                      console.log(error,1111)
                    this.spinShow = false;
                    alert(error);
                });
        },

        save() {
            console.log("保存参数pll", global.pllTempJSON);
        },
    },
};
</script>
<style scoped>
.sider-tree {
    background: #fff;
    border: 1px solid #dcdee2;
    border-radius: 5px;
    margin: 15px 0 15px 15px;
    overflow-y: auto;
}

.tool-bar {
    background-color: #fff;
    margin: 15px;
    border-radius: 5px;
    border: 1px solid #dcdee2;
}

.content {
    margin: 0 15px 15px 15px;
    border: 1px solid #dcdee2;
    border-radius: 5px;
    background-color: #fff;
    overflow-y: auto;
}
/* .img {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */
.data{
    padding: 0 25% 0 25%;
}

.gis-img{
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.gis-box{
  width: fit-content;
  margin: auto;
  position:relative
}
.gis-po{
  position:absolute ;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #000;
}
</style>
