<template>
    <div class="main">
        <HomeBtn/>
        <div class="login-main">
            <div class="logo">
                <div class="logo-all">
                    <img
                        src="@/assets/login_logo.jpg"
                        alt=""
                        class="logo-img"
                    />
                </div>
            </div>

            <el-input
                v-model="phoneNum"
                type="text"
                placeholder="请输入账号"
                class="input-box"
                size="large"
            >
            </el-input>

            <el-input
                v-model="password"
                type="password"
                placeholder="请输入密码"
                show-password
                class="input-box"
                size="large"
            />
            <el-row>
                <el-col :span="12">
                    <div class="forget-password" @click="toRegister">
                        注册账户
                    </div></el-col
                >
                <el-col :span="12"> </el-col>
            </el-row>

            <Button
                size="large"
                type="primary"
                class="login-button"
                @click="login()"
                >登 录</Button
            >
            <div class="check-box">
                <el-checkbox
                    style="float: left"
                    v-model="privateCheck"
                    label=" "
                    size="large"
                />
                <div class="check-text" @click="showPrivacy">
                    我已阅读并同意C-SAT服务协议和隐私政策
                </div>
            </div>
        </div>
        <h3 class="title">面向 “双碳” 立足氢能安全</h3>
        <h3 class="title title2">做氢能QRA领域第一品牌</h3>
    </div>
    <el-dialog
        v-model="showPrivacyDialog"
        title="免责声明"
        width="40%"
    >
        <div>
            欢迎使用C-SAT。在使用本软件之前，请仔细阅读以下免责声明。 
            <div>1. 免责声明</div>
            <div style=" text-indent: 2em;">1.1 本软件的数据仅供参考用。团队针对开发的模型及功能均已进行了验证，得到的计算结果与实验结果一致性较好，但实际使用中计算结果与使用人员操作、知识水平等相关，使用者应依据自身经验对结果准确性进行判断。</div>
            <div>2. 法律适用</div>
            <div style=" text-indent: 2em;">2.1 本免责声明受到适用法律的约束，并且根据适用法律进行解释和执行。</div>
            <div>3. 更新免责声明</div>
            <div style=" text-indent: 2em;">3.1 我们保留随时更新或修改本免责声明的权利。任何更新或修改都将在本页面上发布，并自发布之日起生效。</div>
            <div style=" text-indent: 2em;">请注意，本免责声明不排除或限制根据适用法律可能无法排除或限制的任何权利。如果您不同意本免责声明的任何部分，或者不符合适用法律的任何权利，则不得使用本软件。</div>
               <div style=" text-indent: 2em;">本软件免责声明最终解释权归C-SAT开发团队所有。</div>
               <div style=" text-indent: 2em;">通过使用本软件，您表示已阅读、理解并同意本免责声明的全部条款和条件。</div>
            
            
        </div>
    </el-dialog>
</template>
  <script>
import http from "../../assets/axiosInstance";
import {checkToken} from '../../utils/checkToken'
import HomeBtn from "@/components/HomeBtn.vue";
export default {
  components:{HomeBtn},
    data() {
        return {
            phoneNum: "",
            password: "",
            privateCheck: true,
            rememberMe: true,
            showPrivacyDialog: false,
        };
    },
    methods: {
        showPrivacy() {
            this.showPrivacyDialog=true
        },
        toRegister() {
            this.$router.push("/register");
        },
        async login() {
            if (!this.privateCheck) {
                this.$Message["error"]({
                    background: true,
                    content: "请先勾选c-sat隐私政策以及用户协议",
                });
            }
            if (this.privateCheck) {
                let loginForm = {
                    username: this.phoneNum,
                    password: this.password,
                };
                if(!this.phoneNum.includes('csat')){
                  this.$message.error('账号或密码错误')
                  return false
                }
                http({
                    url: "/login",
                    method: "post",
                    data: loginForm,
                })
                    .then((res) => {
                        if (!res.data.login) {
                            this.$Message["error"]({
                                background: true,
                                content: "账号或密码错误",
                            });
                        }
                        if (res.data.login) {
                            localStorage.setItem("token", res.data.token);
                            localStorage.setItem("name", this.phoneNum);
                            this.$router.push("/qra");
                            checkToken()
                        }
                    })
                    .catch(function (error) {});
            }
        },
    },
};
</script>
  <style lang="scss" scoped>
.main {
    width: 100%;
    height: 100%;
    position: fixed;
    background: url(../../assets/jsconfig.png) 0% 0% / 100% 100%;
    .title {
        position: absolute;
        font-size: 32px;
        color: #fff;
        margin: 0;
        left: calc(50% - 300px);
        top: calc(50% + 160px);
        &.title2 {
            left: calc(50% - 120px);
            top: calc(50% + 210px);
        }
    }
}
.login-main {
    position: absolute;
    width: 350px;
    height: 380px;
    border-radius: 20px;
    top: calc(50% - 50px);
    left: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    box-shadow: var(--el-box-shadow-dark);
}
.input-box {
    margin: 0 40px 15px 40px;
    width: 270px;
}
.logo {
    width: 100%;
    height: 100px;
    position: relative;
}
.logo-img {
    width: 270px;
    float: left;
    margin: 0 0 20px 40px;
}

.forget-password {
    width: 70px;
    color: #909399;
    font-size: 14px;
    margin: 0 0 20px 40px;
    transition: all 0.3s;
    cursor: pointer;
}
.forget-password:hover {
    color: #303133;
}
.login-button {
    width: 270px;
    margin: 15px 40px 0 40px;
}
.check-box {
    margin: 5px 40px 0 40px;
}
.check-text {
    line-height: 40px;
    font-size: 12px;
    float: left;
    cursor: pointer;
}
.remember-me-check {
    margin: 0 40px 0 0;
    float: right;
    transform: translate(0, -10px);
}
.remember-me {
    font-size: 14px;
}
</style>