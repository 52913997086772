import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import pll from '../views/Pll.vue'
import plume from '../views/plume.vue'
import jetflame from '../views/jetflame.vue'
import overpressure from "../views/overpressure.vue";
import accumulation from '../views/accumulation.vue'
import singlePoolFire from '../views/singlePoolFire.vue'
import qra from '../views/qra.vue'
import Login from '../views/login/login.vue'
import Register from '../views/login/register.vue'
import http from "../assets/axiosInstance";

import { ElMessage } from 'element-plus'
const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'C-SAT 登录页'
        },
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            title: 'C-SAT 注册页'
        },
        component: Register
    },
    {
        path: '/',
        name: 'HomeView',
        component: HomeView,
        meta: {
            keepAlive: true,
            title: 'C-SAT'
        },
        redirect: '/qra',
        children: [
            {
                path: '/pll',
                name: 'pll',
                component: pll
            },
            {
                path: '/plume',
                name: 'plume',
                component: plume
            },
            {
                path: '/jetflame',
                name: 'jetflame',
                component: jetflame
            },
            {
                path: '/overpressure',
                name: 'overpressure',
                component: overpressure
            },
            {
                path: '/accumulation',
                name: 'accumulation',
                component: accumulation
            },
            {
                path: '/singlePoolFire',
                name: 'singlePoolFire',
                component: singlePoolFire
            },
            {
                path: '/qra',
                name: 'qra',
                component: qra
            }
        ]
    },

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    to.meta.title && (document.title = to.meta.title);
    console.log(to.path,1111)
    if(['/login','/register'].includes(to.path)){
      next();
    }else{
      const token=localStorage.getItem('token')
      const username=localStorage.getItem('name')
      if(!token||!username){
        router.push('/login')
        return
      }else{
        next()
      }
      // else{
      //   http({
      //     url: "/checkToken",
      //     method: "post",
      //     data: {
      //         token,
      //         username
      //     },
      // })
      //     .then((res) => {
      //         if(!res.data.available){
      //             localStorage.clear()
      //             ElMessage.error('当前登录已过期，请重新登录')
      //             next('/login')
      //         }else{
      //           next()
      //         }
      //     })
      // }
    }
});
export default router
