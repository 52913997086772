<template>
    <Tree :data="dataTree"></Tree>
    <Modal
        v-model="setModule"
        title="模型选择"
        @on-ok="submitSetModel"
        :mask-closable="false"
        draggable
        sticky
        width="700"
        class-name="vertical-center-modal"
    >
        <Form :model="form" :label-width="100">
            <FormItem label="虚拟喷嘴模型">
                <Select v-model="form.nozzleModel">
                    <Option value="yuce">Yuceil/Otugen</Option>
                    <Option value="birc">Birch</Option>
                    <Option value="bir2">Birch2</Option>
                    <Option value="ewan">Ewan/Moodie</Option>
                    <Option value="molk">Molkov</Option>
                </Select>
            </FormItem>
            <FormItem label="流体相态">
                <Select v-model="form.fluid_phase">
                    <Option value="none">气体</Option>
                    <Option value="gas">饱和气体</Option>
                    <Option value="liquid">饱和液体</Option>
                </Select>
            </FormItem>
        </Form>
    </Modal>
    <Modal
        v-model="output"
        title="输出设置"
        @on-ok="submitOutput"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form :model="form" :label-width="120">
            <Table
                border
                size="small"
                :columns="columnsX"
                :data="dataX"
            >
            <template #x="{row,index}">
              <Input v-model="row.x" @change="changeRow(row.x,'x',index)"/>
            </template>
            <template #y="{row,index}">
              <Input v-model="row.y" @change="changeRow(row.y,'y',index)"/>
            </template>
            <template #z="{row,index}">
              <Input v-model="row.z" @change="changeRow(row.z,'z',index)"/>
            </template>
            </Table>

            <FormItem label="热辐射关注轮廓" style="margin-top: 20px">
                <Input
                    v-model="contours"
                    placeholder="请输入等值线数，以空格隔开"
            >
            <template #append>
              kW/m<sup>2</sup>
            </template>
            </Input>
            </FormItem>
        </Form>
    </Modal>
    <Modal
        v-model="environmentParam"
        title="环境参数"
        @on-ok="submitEnvironmentParam"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form :model="form" label-position="right" :label-width="100">
            <FormItem label="环境温度">
                <Input v-model="form.amb_temperature" type="number">
                    <template #append> K</template>
                </Input>
            </FormItem>
            <FormItem label="环境压力" type="number">
                <Input v-model="form.amb_pressure">
                    <template #append> Pa</template>
                </Input>
            </FormItem>
            <FormItem label="相对湿度" type="number">
                <Input v-model="form.humidity"> </Input>
            </FormItem>
        </Form>
    </Modal>
    <Modal
        v-model="capacityParam"
        title="容器参数"
        @on-ok="submitCapacityParam"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form :model="form" label-position="right" :label-width="100">
            <FormItem label="泄漏直径">
                <Input v-model="form.orificeDiameter" type="number">
                    <template #append> m</template>
                </Input>
            </FormItem>
            <FormItem label="泄漏角度" type="number" >
                <Input v-model="form.orificeAngleTemp" @on-change="changeOrificeAngleTemp">
                    <template #append>°</template>
                </Input>
            </FormItem>
            <FormItem label="泄漏高度" type="number">
                <Input v-model="form.orificeHeight">
                    <template #append> m</template>
                </Input>
            </FormItem>
            <FormItem label="流体压力" type="number">
                <Input v-model="form.fluid_pressure">
                    <template #append> Pa</template>
                </Input>
            </FormItem>
            <FormItem label="流体温度" v-if="form.fluid_phase==='none'" type="number">
                <Input v-model="form.fluid_temperature">
                    <template #append> K</template>
                </Input>
            </FormItem>
        </Form>
    </Modal>
</template>
<script>
import global from "../assets/global.vue";
import { resolveComponent } from "vue";

export default {
    name: "jetflameTree",
    data() {
        return {
            contours: "1.577 4.732 25.237",
            columnsX: [
                {
                    title: "X 坐标 (m)",
                    key: "x",
                    align: "center",
                    slot:'x'
                    // render: (h, params) => {
                    //     return (
                    //         <Input
                    //             modelValue={params.row.x}
                    //             placeholder="请输入坐标值"
                    //             border={false}
                    //             onChange={(event) => {
                    //                 //获取编辑行的index和编辑字段名，对表格数据进行重新赋值
                    //                 this.dataX[params.index]["x"] = parseFloat(
                    //                     event.target.value
                    //                 );
                    //             }}
                    //         />
                    //     );
                    // },
                },
                {
                    title: "Y 坐标 (m)",
                    key: "y",
                    align: "center",
                    slot:'y'
                    // render: (h, params) => {
                    //     return (
                    //         <Input
                    //             modelValue={params.row.z}
                    //             placeholder="请输入坐标值"
                    //             border={false}
                    //             onChange={(event) => {
                    //                 //获取编辑行的index和编辑字段名，对表格数据进行重新赋值
                    //                 this.dataX[params.index]["z"] = parseFloat(
                    //                     event.target.value
                    //                 );
                    //             }}
                    //         />
                    //     );
                    // },
                },
                {
                    title: "Z 坐标 (m)",
                    key: "z",
                    align: "center",
                    slot:'z'
                    // render: (h, params) => {
                    //     return (
                    //         <Input
                    //             modelValue={params.row.x}
                    //             placeholder="请输入坐标值"
                    //             border={false}
                    //             onChange={(event) => {
                    //                 //获取编辑行的index和编辑字段名，对表格数据进行重新赋值
                    //                 this.dataX[params.index]["x"] = parseFloat(
                    //                     event.target.value
                    //                 );
                    //             }}
                    //         />
                    //     );
                    // },
                },
            ],

            dataX: [
                {
                    x: 0.01,
                    y: 1,
                    z: 0.01,
                },
                {
                    x: 0.5,
                    y: 1,
                    z: 0.5,
                },
                {
                    x: 1,
                    y: 1,
                    z: 0.5,
                },
                {
                    x: 2,
                    y: 1,
                    z: 1,
                },
                {
                    x: 0,
                    y: 0,
                    z: 0,
                },
                {
                    x: 0,
                    y: 0,
                    z: 0,
                },
                {
                    x: 0,
                    y: 0,
                    z: 0,
                },
                {
                    x: 0,
                    y: 0,
                    z: 0,
                },
                {
                    x: 0,
                    y: 0,
                    z: 0,
                },
                {
                    x: 0,
                    y: 0,
                    z: 0,
                },
            ],
            ////////
            setModule: false,
            output: false,
            environmentParam: false,
            capacityParam: false,
            ////////
            form: {...global.jetflameJSON,orificeAngleTemp:0},
            dataTree: [
                {
                    title: "模型选择",
                    expand: true,
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.setModule = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "logo-codepen",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                },
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.output = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "ios-create",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "输出设置",
                    expand: true,
                },
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.environmentParam = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "ios-compass",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "环境参数",
                    expand: true,
                },
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.capacityParam = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "md-battery-dead",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "容器参数",
                    expand: true,
                },
            ],
        };
    },
    created() {
        global.jetflameTempJSON = this.form;
    },
    watch: {
        form: {
            handler(newVal, oldVal) {
                global.jetflameTempJSON = newVal;
            },
        },
    },
    methods: {
        changeRow(val,key,index){
          this.dataX[index][key]=Number(val)
        },
        changeOrificeAngleTemp(){
          this.form.orificeAngle=(this.form.orificeAngleTemp)*Math.PI/180
        },
        submitSetModel() {
            this.setModule = false;
            console.log(this.form, global.jetflameTempJSON);
        },
        submitCapacityParam() {
            this.capacityParam = false;
        },
        submitOutput() {
            //处理contours
            let arr = this.contours;
            let arr_string = arr.split(" ");
            for (let i = 0; i < arr_string.length; i++) {
                arr_string[i] = parseFloat(arr_string[i]);
            }
            this.form.contours = arr_string;
            //处理XYZPods
            let x_arr = [];
            let y_arr = [];
            let z_arr = [];
            for (let i = 0; i < 10; i++) {
                x_arr.push(this.dataX[i]["x"]);
                y_arr.push(this.dataX[i]["y"]);
                z_arr.push(this.dataX[i]["z"]);
            }
            this.form.XPOS = x_arr;
            this.form.YPOS = y_arr;
            this.form.ZPOS = z_arr;

            console.log(this.form.ZPOS);
            this.output = false;
        },
        submitEnvironmentParam() {
            this.environmentParam = false;
        },
    },
};
</script>
<style scoped>
</style>