<template>
    <div style="float: right">
        <!-- <Button type="text" @click="see_page()">查看手册</Button>
        <Divider type="vertical" /> -->
        <!-- <Button type="text" @click="aboutUs()">关于我们</Button> -->
    </div>
    <Modal
        v-model="about_us"
        title="关于我们"
        width="40%"
        class="model"
        :footer-hide="true"
    >
        <div class="text">
            C-SAT（氢安全QRA软件）由海归博士张彬教授带领的国际化研发团队开发，是行业首个专门面向氢安全的QRA软件，旨在解决由于氢的特殊危害特性和工艺条件带来的关键安全问题，相对于通用QRA软件更能体现专业性和科学性，可对氢能行业开展全生命周期的定量安全评估。
        </div>
        <div class="text">
            C-SAT软件技术研发团队成员包括来自美国、欧洲、巴西等国家地区的知名专家，长期开展氢安全相关科研工作，系统研究并总结了氢能与传统燃料差异性（设备失效概率、点火概率、泄漏扩散模型等），开发的模型经过数据验证，可靠性高，行业需求迫切。
        </div>
        <div class="text">
            如需相关技术支持，可关注微信公众号或访问实验室网站，联系我们。
        </div>
        <div align="center">
            <img src="../assets/aboutus.png" alt="" style="height: 150px;">
        </div>
    </Modal>
</template>
<script>
export default {
    name: "FooterMsg",
    data() {
        return {
            about_us: false,
        };
    },
    methods: {
        see_page() {
            alert("查看手册");
        },
        aboutUs() {
            this.about_us = true;
        },
        closeAboutUs() {
            this.about_us = false;
        },
    },
};
</script>
<style scoped>
.model {
    padding: 0 !important;
}
.text {
    font-size: 16px;
    color: black;
    line-height: 28px;
    margin-bottom: 20px;
}
</style>