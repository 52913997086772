
<template>
    <div class="layout">
        <Layout>
            <Header style="position: fixed; width: 100%">
                <Menu mode="horizontal" theme="dark" active-name="1">
                    <div class="layout-logo">
                        <img
                            src="../assets/logo_line.jpg"
                            alt=""
                            class="layout-logo"
                        />
                    </div>

                    <!-- <div class="layout-nav" @click="logout">
                        {{ name }}
                         <div @click="toCsat" class="back-home">返回主页</div>
                    </div> -->
                    <div class="bar">
                    <span class="bar-button" @click="logout(username)">{{
                      name
                    }}</span
                    >｜<span @click="toCsat" class="bar-button">返回主页</span
                    >｜<span class="bar-button" @click="openInfo"
                        >关于我们</span
                    >
                </div>

                </Menu>
            </Header>
            <Layout
                style="
                    position: fixed;
                    top: 64px;
                    bottom: 0px;
                    left: 0px;
                    right: 0px;
                "
            >
                <Sider
                    hide-trigger
                    class="sider"
                    :style="{
                        background: '#fff',
                        'border-right': '1px solid #dcdee2',
                    }"
                >
                    <Menu
                        theme="light"
                        width="auto"
                        @on-select="selectMenu"
                        :active-name="activeRouter"
                    >
                        <MenuGroup title="概率模型">
                            <MenuItem name="/qra">
                                🗺️
                                <span>个人风险与社会风险</span>
                            </MenuItem>
                            <MenuItem name="/pll">
                                <svg
                                    class="icon"
                                    aria-hidden="true"
                                    style="width: 20px"
                                >
                                    <use
                                        xlink:href="#icon-fengxianzhenduan"
                                    ></use>
                                </svg>
                                <span>PLL计算</span>
                            </MenuItem>
                        </MenuGroup>
                        <MenuGroup title="物理模型">
                            <MenuItem name="/plume">
                                <svg
                                    class="icon"
                                    aria-hidden="true"
                                    style="width: 20px"
                                >
                                    <use xlink:href="#icon-yangan"></use>
                                </svg>
                                <span>羽流分布</span>
                            </MenuItem>
                            <MenuItem name="/accumulation">
                                <svg
                                    class="icon"
                                    aria-hidden="true"
                                    style="width: 20px"
                                >
                                    <use xlink:href="#icon-tianqi_icon"></use>
                                </svg>
                                <span>累积效应</span>
                            </MenuItem>
                            <MenuItem name="/jetflame">
                                <svg
                                    class="icon"
                                    aria-hidden="true"
                                    style="width: 20px"
                                >
                                    <use xlink:href="#icon-huozaishigu"></use>
                                </svg>
                                <span>喷射火热辐射</span>
                            </MenuItem>
                            <MenuItem name="/overpressure">
                                <svg
                                    class="icon"
                                    aria-hidden="true"
                                    style="width: 20px"
                                >
                                    <use xlink:href="#icon-baozha"></use>
                                </svg>
                                <span>爆炸超压</span>
                            </MenuItem>
                            <!-- <MenuItem name="singlePoolFire">
                                <svg
                                    class="icon"
                                    aria-hidden="true"
                                    style="width: 20px"
                                >
                                    <use xlink:href="#icon-remen"></use>
                                </svg>
                                <span>池火热辐射（甲烷）</span>
                            </MenuItem> -->
                        </MenuGroup>
                    </Menu>
                </Sider>
                <Layout>
                    <Content
                        :style="{
                            minHeight: '280px',
                            background: '#f8f8f9',
                        }"
                    >
                        <router-view v-slot="{ Component }">
                            <keep-alive exclude="About">
                                <component :is="Component" />
                            </keep-alive>
                        </router-view>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
        <Modal
          v-model="about_us"
          title="关于我们"
          width="40%"
          class="model"
          :footer-hide="true"
      >
          <div class="text" style=" text-indent: 2em;">
              C-SAT（氢安全QRA软件）由海归博士张彬教授带领的国际化研发团队开发，是行业首个专门面向氢安全的QRA软件，旨在解决由于氢的特殊危害特性和工艺条件带来的关键安全问题，相对于通用QRA软件更能体现专业性和科学性，可对氢能行业开展全生命周期的定量安全评估。
          </div>
          <div class="text" style=" text-indent: 2em;">
              C-SAT软件技术研发团队成员包括来自美国、欧洲、巴西等国家地区的知名专家，长期开展氢安全相关科研工作，系统研究并总结了氢能与传统燃料差异性（设备失效概率、点火概率、泄漏扩散模型等），开发的模型经过数据验证，可靠性高，行业需求迫切。
          </div>
          <div class="text" style=" text-indent: 2em;">
              如需相关技术支持，可关注微信公众号或访问实验室网站，联系我们。
          </div>
          <div align="center">
              <img src="../assets/aboutus.png" alt="" style="height: 150px;">
          </div>
      </Modal>
    </div>
    <Guide></Guide>
</template>
<script>
import router from "@/router";
import Guide from "../components/guide.vue";
import FooterMsg from "@/components/footer.vue";
import http from "../assets/axiosInstance";
export default {
    name: "HomeView",
    data() {
        return {
            activeRouter: this.$route.path,
            name: localStorage.getItem("name"),
            about_us:false
        };
    },
    components: { Guide, FooterMsg },
    created() {
        // http({
        //     url: "/checkToken",
        //     method: "post",
        //     data: {
        //         token: localStorage.getItem("token"),
        //         username: localStorage.getItem("name"),
        //     },
        // })
        //     .then((res) => {
        //         if (!res.data.available) {
        //             localStorage.clear();
        //             this.$router.push("/login");
        //         }
                
        //         // else {
        //         //     this.$router.push("/qra");
        //         // }
        //     })
        //     .catch(function (error) {});
    },
    methods: {
        openInfo(){
          this.about_us=true
        },
        toCsat(){
            window.location.href="http://processsafetytool.com";
        },
        logout() {
            this.$Modal.confirm({
                title: "退出",
                content: "<p>是否确认退出c-sat？</p>",
                onOk: () => {
                    http({
                        url: "/logout",
                        method: "post",
                        data: {
                            token: localStorage.getItem("token"),
                            username: localStorage.getItem("name"),
                        },
                    })
                        .then((res) => {
                            localStorage.clear()
                            this.$router.push('/login')
                        })
                        .catch(function (error) {});
                },
                onCancel: () => {
                    //this.$Message.info('Clicked cancel');
                },
            });
        },
        selectMenu(name) {
            // if (name == "pll") {
            //     this.$router.push("pll");
            //     sessionStorage.setItem("router", "pll");
            // }
            // if (name == "plume") {
            //     this.$router.push("plume");
            //     sessionStorage.setItem("router", "plume");
            // }
            // if (name == "jetflame") {
            //     this.$router.push("jetflame");
            //     sessionStorage.setItem("router", "jetflame");
            // }
            // if (name == "overpressure") {
            //     this.$router.push("overpressure");
            //     sessionStorage.setItem("router", "overpressure");
            // }
            // if (name == "accumulation") {
            //     this.$router.push("accumulation");
            //     sessionStorage.setItem("router", "accumulation");
            // }
            // if (name == "singlePoolFire") {
            //     this.$router.push("singlePoolFire");
            //     sessionStorage.setItem("router", "singlePoolFire");
            // }
            // if (name == "qra") {
            //     this.$router.push("qra");
            //     sessionStorage.setItem("router", "qra");
            // }
            this.$router.push(name)
        },
    },
};
</script>
<style>
.back-home{
    color: white;
    float: right;

    line-height: 40px;
    font-size: 16px;
    padding: 0 20px 0 20px;
    border: 2px solid white;
    margin: 10px 0 10px 20px;
    border-radius: 5px;
}
.layout {
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: fixed;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.layout-logo {
    height: 45px;
    border-radius: 3px;
    float: left;
    transform: translate(-15%, 10%);
}

.layout-nav {
    float: right;
    font-size: 25px;
    color: white;
    cursor: pointer;
}

.choose-icon {
    cursor: pointer;
}

.choose-icon:hover {
    background-color: #808695;
    border-radius: 10px;
}

.sider {
    background: #fff;
    border: 1px solid #dcdee2;
    border-radius: 5px;
    margin: 15px 0 15px 15px;
}

.bar{
  float: right;
  height: 100%;
  color: #fff;
  cursor: pointer;
}
</style>
