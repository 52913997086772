<template>
    <Tree :data="dataTree"></Tree>
    <Modal
        v-model="setModule"
        title="燃烧模型"
        @on-ok="submitSetModel"
        :mask-closable="false"
        draggable
        sticky
        width="700"
        class-name="vertical-center-modal"
    >
        <Form
            :model="form"
            label-position="right"
            :label-width="100"
            style="width: 400px"
        >
            <FormItem label="分子量">
                <Input v-model="form.MolecularWeight" type="number">
                    <template #append>g/mol</template></Input
                >
            </FormItem>
            <FormItem label="燃烧热" type="number">
                <Input v-model="form.CombustionHeat">
                    <template #append>J/kg</template></Input
                >
            </FormItem>
            <FormItem label="比热容" type="number">
                <Input v-model="form.SpecificHeat"
                    ><template #append>J/(K·kg)</template>
                </Input>
            </FormItem>
            <FormItem label="标准沸点">
                <Input v-model="form.NormalBoilingPoint" type="number">
                    <template #append>K</template></Input
                >
            </FormItem>
            <FormItem label="液体密度" type="number">
                <Input v-model="form.LiquidDensity">
                    <template #append>kg/m<sup>3</sup></template></Input
                >
            </FormItem>
            <FormItem label="火焰温度" type="number">
                <Input v-model="form.FlameTemperature">
                    <template #append>K</template>
                </Input>
            </FormItem>
        </Form>
    </Modal>
    <Modal
        v-model="poolShape"
        title="池形状"
        @on-ok="submitPoolShape"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form :model="form" :label-width="120" style="width: 400px">
            <FormItem label="池形状">
                <Select v-model="form.PoolShape" @on-change="chooseShape">
                    <Option value="Circle">圆形</Option>
                    <Option value="Rectangle">方形</Option>
                </Select>
            </FormItem>
            <FormItem label="圆池半径" v-if="circle">
                <Input v-model="form.PoolDiameter" type="number">
                    <template #append>m</template></Input
                >
            </FormItem>
            <FormItem label="方形池宽度" type="number" v-if="rectangle">
                <Input v-model="form.PoolWidth">
                    <template #append>m</template></Input
                >
            </FormItem>
            <FormItem label="方形池长度" type="number" v-if="rectangle">
                <Input v-model="form.PoolLength"
                    ><template #append>m</template>
                </Input>
            </FormItem>
            <FormItem label="火焰基础高度">
                <Input v-model="form.FlameBaseHeight" type="number">
                    <template #append>m</template></Input
                >
            </FormItem>
            <FormItem label="目标高度" type="number">
                <Input v-model="form.TargetHeight">
                    <template #append>m</template></Input
                >
            </FormItem></Form
        >
    </Modal>
    <Modal
        v-model="environmentParam"
        title="环境参数"
        @on-ok="submitEnvironmentParam"
        :mask-closable="false"
        draggable
        sticky
        width="700"
    >
        <Form :model="form" :label-width="100" style="width: 400px">
            <FormItem label="太阳光照条件">
                <Select v-model="form.InsolationType">
                    <Option value="ClearNight">晴朗夜晚</Option>
                    <Option value="CloudyNight">多云夜晚</Option>
                    <Option value="ClearSkiesWithSlightSun">轻微日光</Option>
                    <Option value="AllOtherSkies">一般日光</Option>
                    <Option value="ClearSkiesWithStrongSun">强烈日光</Option>
                </Select>
            </FormItem>
            <!-- <FormItem label="环境压力">
                <Input v-model="form.AmbientPressure" type="number">
                    <template #append>Pa</template></Input
                >
            </FormItem> -->
            <FormItem label="环境风速" type="number">
                <Input v-model="form.WindSpeed">
                    <template #append>m/s</template></Input
                >
            </FormItem>
            <FormItem label="风速参考高度" type="number">
                <Input v-model="form.WindSpeedReferenceHeight"
                    ><template #append>m</template>
                </Input>
            </FormItem>
            <FormItem label="环境温度">
                <Input v-model="form.AmbientTemperature" type="number">
                    <template #append>K</template></Input
                >
            </FormItem>
            <FormItem label="相对湿度" type="number">
                <Input v-model="form.RelativeHumidity"> </Input>
            </FormItem>
            <FormItem label="地面粗糙度" type="number">
                <Input v-model="form.RoughnessLength">
                    <template #append>m</template>
                </Input>
            </FormItem>
            <FormItem label="演算距离" type="number">
                <Input v-model="testPointList" @on-change="handleTextPointList">
                    <template #append>m</template>
                </Input>
            </FormItem>
            <FormItem label="冯卡曼常数" type="number">
                <Input v-model="form.VonKarmenConstant" disabled> </Input>
            </FormItem>
        </Form>
    </Modal>
    <Modal
        v-model="drawChoice"
        title="燃烧模型"
        @on-ok="submitSetModel"
        :mask-closable="false"
        draggable
        sticky
        width="700"
        class-name="vertical-center-modal"
    >
        <Form
            :model="form"
            label-position="right"
            :label-width="100"
            style="width: 400px"
        >
            <FormItem label="热辐射值" type="number">
                <Input v-model="textFluxList" @on-change="handleTextFluxList">
                    <template #append>kW/m<sup>2</sup></template>
                </Input>
            </FormItem>
        </Form>
    </Modal>
</template>
<script>
import global from "../assets/global.vue";
import { resolveComponent } from "vue";
export default {
    name: "singlePoolFireTree",
    data() {
        return {
            testPointList: "",
            textFluxList: "45 25",
            ////////
            rectangle: false,
            circle: true,
            setModule: false,
            poolShape: false,
            environmentParam: false,
            capacityParam: false,
            drawChoice: false,
            ////////
            form: global.singlePoolFireJSON,
            dataTree: [
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.poolShape = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "md-cube",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "池形状",
                    expand: true,
                },
                {
                    title: "燃烧模型",
                    expand: true,
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.setModule = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "md-flame",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                },

                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.environmentParam = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "ios-compass",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "环境参数",
                    expand: true,
                },
                {
                    render: (h, { root, node, data }) => {
                        return h(
                            "span",
                            {
                                onClick: () => (this.drawChoice = true),
                            },
                            [
                                h("span", [
                                    h(resolveComponent("Icon"), {
                                        type: "ios-compass",
                                        style: {
                                            marginLeft: "8px",
                                            marginRight: "4px",
                                        },
                                    }),
                                    h("span", data.title),
                                ]),
                            ]
                        );
                    },
                    title: "绘图选项",
                    expand: true,
                },
            ],
        };
    },
    created() {
        global.singlePoolFireTempJSON = this.form;
    },
    watch: {
        form: {
            handler(newVal, oldVal) {
                global.singlePoolFireTempJSON = newVal;
            },
        },
    },
    methods: {
        handleTestPointList() {
            //处理contours
            let arr = this.testPointList;
            let arr_string = arr.split(" ");
            for (let i = 0; i < arr_string.length; i++) {
                arr_string[i] = parseFloat(arr_string[i]);
            }
            this.form.TestPointList = arr_string;
        },
        handleTextFluxList() {
            //处理contours
            let arr = this.textFluxList;
            let arr_string = arr.split(" ");
            for (let i = 0; i < arr_string.length; i++) {
                arr_string[i] = parseFloat(arr_string[i]);
            }
            this.form.TestFluxList = arr_string;
        },
        chooseShape(value) {
            if (value == "Rectangle") {
                this.circle = false;
                this.rectangle = true;
            }
            if (value == "Circle") {
                this.circle = true;
                this.rectangle = false;
            }
        },
        submitSetModel() {
            this.setModule = false;
        },
        submitPoolShape() {
            this.poolShape = false;
        },
        submitEnvironmentParam() {
            this.environmentParam = false;
        },
    },
};
</script>
<style scoped>
</style>