<template>
    <Tree :data="dataTree"></Tree>
    <div>
        <Modal
            v-model="AccidentVisible"
            title="全口径泄漏事件概率"
            @on-ok="AccidentVisible = false"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <Row>
                    <Col span="6">
                        <div class="normal-table table-title">
                            <span>事件</span>
                        </div>
                    </Col>
                    <Col span="6">
                        <div class="normal-table table-title">
                            <span>分布类型</span>
                        </div>
                    </Col>
                    <Col span="6">
                        <div class="normal-table table-title">
                            α、(μ)、期望值
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-title table-border-right"
                        >
                            β、(σ)
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <div class="normal-table table-left">喷头喷出</div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Select v-model="form.Accident.noz_po_dist">
                                <Option value="beta">Beta</Option>
                                <Option value="logn">Lognormal</Option>
                                <Option value="expv">期望值</Option>
                            </Select>
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Input
                                v-model="form.Accident.noz_po_a"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content table-border-right"
                        >
                            <Input
                                v-model="form.Accident.noz_po_b"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <div class="normal-table table-left">喷头关闭失效</div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Select v-model="form.Accident.noz_ftc_dist">
                                <Option value="beta">Beta</Option>
                                <Option value="logn">Lognormal</Option>
                                <Option value="expv">期望值</Option>
                            </Select>
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Input
                                v-model="form.Accident.noz_ftc_a"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content table-border-right"
                        >
                            <Input
                                v-model="form.Accident.noz_ftc_b"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <div class="normal-table table-left">
                            罗曼塞立格拉断阀失效
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Select v-model="form.Accident.coupling_ftc_dist">
                                <Option value="beta">Beta</Option>
                                <Option value="logn">Lognormal</Option>
                                <Option value="expv">期望值</Option>
                            </Select>
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Input
                                v-model="form.Accident.coupling_ftc_a"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content table-border-right"
                        >
                            <Input
                                v-model="form.Accident.coupling_ftc_b"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <div class="normal-table table-left">
                            泄压阀开启失效
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Select v-model="form.Accident.pvalve_fto_dist">
                                <Option value="beta">Beta</Option>
                                <Option value="logn">Lognormal</Option>
                                <Option value="expv">期望值</Option>
                            </Select>
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Input
                                v-model="form.Accident.pvalve_fto_a"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content table-border-right"
                        >
                            <Input
                                v-model="form.Accident.pvalve_fto_b"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <div class="normal-table table-left">
                            手动阀关闭失效
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Select v-model="form.Accident.mvalve_ftc_dist">
                                <Option value="beta">Beta</Option>
                                <Option value="logn">Lognormal</Option>
                                <Option value="expv">期望值</Option>
                            </Select>
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Input
                                v-model="form.Accident.mvalve_ftc_a"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content table-border-right"
                        >
                            <Input
                                v-model="form.Accident.mvalve_ftc_b"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <div class="normal-table table-left">
                            电磁阀关闭失效
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Select v-model="form.Accident.svalve_ftc_dist">
                                <Option value="beta">Beta</Option>
                                <Option value="logn">Lognormal</Option>
                                <Option value="expv">期望值</Option>
                            </Select>
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Input
                                v-model="form.Accident.svalve_ftc_a"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content table-border-right"
                        >
                            <Input
                                v-model="form.Accident.svalve_ftc_b"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <div class="normal-table table-left">
                            电磁阀常规失效
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Select v-model="form.Accident.svalve_ccf_dist">
                                <Option value="beta">Beta</Option>
                                <Option value="logn">Lognormal</Option>
                                <Option value="expv">期望值</Option>
                            </Select>
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Input
                                v-model="form.Accident.svalve_ccf_a"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content table-border-right"
                        >
                            <Input
                                v-model="form.Accident.svalve_ccf_b"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <div class="normal-table table-left">驶离</div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Select v-model="form.Accident.driveoff_dist">
                                <Option value="beta">Beta</Option>
                                <Option value="logn">Lognormal</Option>
                                <Option value="expv">期望值</Option>
                            </Select>
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Input
                                v-model="form.Accident.driveoff_a"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content table-border-right"
                        >
                            <Input
                                v-model="form.Accident.driveoff_b"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span="6">
                        <div class="normal-table table-left">充装超压</div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Select v-model="form.Accident.overp_dist">
                                <Option value="beta">Beta</Option>
                                <Option value="logn">Lognormal</Option>
                                <Option value="expv">期望值</Option>
                            </Select>
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content"
                            style="padding: 10px"
                        >
                            <Input
                                v-model="form.Accident.overp_a"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                    <Col span="6">
                        <div
                            class="normal-table table-content table-border-right"
                        >
                            <Input
                                v-model="form.Accident.overp_b"
                                :border="false"
                                placeholder="请输入"
                            />
                        </div>
                    </Col>
                </Row>
                <div>
                    载具数量<Input
                        v-model="form.Vehicles.num_vehicles"
                        placeholder="请输入"
                    />
                </div>
                <div>
                    每天每车充装次数<Input
                        v-model="form.Vehicles.daily_fuelings"
                        placeholder="请输入"
                    />
                </div>
                <div>
                    每年充装天数<Input
                        v-model="form.Vehicles.vehicle_days"
                        placeholder="请输入"
                    />
                </div>
                <div>
                    年充装频率：{{
                        form.Vehicles.num_vehicles *
                        form.Vehicles.daily_fuelings *
                        form.Vehicles.vehicle_days
                    }}
                </div>
            </div>
        </Modal>
        <Modal
            v-model="components"
            title="组件"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="1000"
            class-name="vertical-center-modal"
        >
            <Form :model="form" :label-width="100" inline>
                <FormItem label="压缩机" class="contours">
                    <Input
                        v-model="form.Components.num_compressors"
                        placeholder="请输入压缩机个数"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="储罐" class="contours">
                    <Input
                        v-model="form.Components.num_vessels"
                        placeholder="请输入储罐个数"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="阀门" class="contours">
                    <Input
                        v-model="form.Components.num_valves"
                        placeholder="请输入阀门个数"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="设备" class="contours">
                    <Input
                        v-model="form.Components.num_instruments"
                        placeholder="请输入设备数"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="结合处" class="contours">
                    <Input
                        v-model="form.Components.num_joints"
                        placeholder="请输入结合处数量"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="软管" class="contours">
                    <Input
                        v-model="form.Components.num_hoses"
                        placeholder="请输入软管数量"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="管道长度" class="contours">
                    <Input
                        v-model="form.Components.pipe_length"
                        placeholder="请输入管道长度"
                    >
                        <template #append> m </template>
                    </Input>
                </FormItem>
                <FormItem label="过滤器" class="contours">
                    <Input
                        v-model="form.Components.num_filters"
                        placeholder="请输入过滤器数量"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="法兰" class="contours">
                    <Input
                        v-model="form.Components.num_flanges"
                        placeholder="请输入法兰数量"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="热交换器" class="contours">
                    <Input
                        v-model="form.Components.num_exchangers"
                        placeholder="请输入热交换器数量"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="冷凝器" class="contours">
                    <Input
                        v-model="form.Components.num_vaporizers"
                        placeholder="请输入冷凝器数量"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="装卸臂" class="contours">
                    <Input
                        v-model="form.Components.num_arms"
                        placeholder="请输入装卸臂数量"
                    >
                        <template #append> 个 </template>
                    </Input>
                </FormItem>
                <FormItem label="附件1" class="contours">
                    <Input
                        v-model="form.Components.num_extra_comp1"
                        placeholder="此处输入附件1"
                    >
                        <template #append>…</template>
                    </Input> </FormItem
                ><FormItem label="附件2" class="contours">
                    <Input
                        v-model="form.Components.num_extra_comp2"
                        placeholder="此处输入附件2"
                    >
                        <template #append>…</template>
                    </Input></FormItem
                ></Form
            >
        </Modal>
        <Modal
            v-model="compressors"
            title="压缩机泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .compressor_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .compressor_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .compressor_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .compressor_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .compressor_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .compressor_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .compressor_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .compressor_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .compressor_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .compressor_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .compressor_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .compressor_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .compressor_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .compressor_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .compressor_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="vessel"
            title="储罐泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vessel_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vessel_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .vessel_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vessel_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vessel_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .vessel_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vessel_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vessel_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .vessel_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vessel_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vessel_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .vessel_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vessel_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vessel_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .vessel_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="valves"
            title="阀门泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.valve_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.valve_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.valve_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.valve_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.valve_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.valve_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.valve_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.valve_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.valve_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.valve_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.valve_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.valve_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.valve_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.valve_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.valve_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="instruments"
            title="设备泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .instrument_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .instrument_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .instrument_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .instrument_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .instrument_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .instrument_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .instrument_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .instrument_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .instrument_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .instrument_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .instrument_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .instrument_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .instrument_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .instrument_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .instrument_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="joints"
            title="结合处泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.joint_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.joint_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.joint_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.joint_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.joint_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.joint_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.joint_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.joint_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.joint_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.joint_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.joint_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.joint_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.joint_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.joint_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.joint_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="hoses"
            title="软管泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.hose_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.hose_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.hose_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.hose_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.hose_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.hose_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.hose_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.hose_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.hose_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.hose_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.hose_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.hose_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.hose_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.hose_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.hose_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="pipe_length"
            title="管道泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.pipe_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.pipe_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.pipe_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.pipe_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.pipe_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.pipe_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.pipe_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.pipe_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.pipe_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.pipe_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.pipe_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.pipe_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.pipe_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.pipe_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.pipe_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="filters"
            title="过滤器泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .filter_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .filter_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .filter_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .filter_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .filter_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .filter_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .filter_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .filter_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .filter_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .filter_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .filter_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .filter_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .filter_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .filter_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .filter_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="flanges"
            title="法兰泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .flange_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .flange_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .flange_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .flange_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .flange_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .flange_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .flange_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .flange_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .flange_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .flange_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .flange_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .flange_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .flange_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .flange_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .flange_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="exchangers"
            title="热交换器泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .exchanger_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .exchanger_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .exchanger_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .exchanger_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .exchanger_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .exchanger_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .exchanger_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .exchanger_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .exchanger_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .exchanger_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .exchanger_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .exchanger_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .exchanger_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .exchanger_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .exchanger_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="vaporizers"
            title="冷凝器泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb
                                            .vaporizer_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="arms"
            title="装卸臂泄漏概率"
            @on-ok="submitComponents"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">
                                <span>µ</span>
                            </div></Col
                        >
                        <Col span="6"
                            ><div class="normal-table table-title">σ</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-title table-border-right"
                            >
                                中位数
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.01%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.arm_leak_probs[0][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.arm_leak_probs[0][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.arm_leak_probs[0][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">
                                0.1%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.arm_leak_probs[1][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.arm_leak_probs[1][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.arm_leak_probs[1][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">1%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.arm_leak_probs[2][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.arm_leak_probs[2][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.arm_leak_probs[2][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div class="normal-table table-left">10%</div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.arm_leak_probs[3][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.arm_leak_probs[3][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.arm_leak_probs[3][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                    <Row>
                        <Col span="6"
                            ><div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%
                            </div></Col
                        >
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.arm_leak_probs[4][0]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-bottom"
                                style="padding: 10px"
                            >
                                <Input
                                    v-model="
                                        form.CompLeakProb.arm_leak_probs[4][1]
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                /></div
                        ></Col>
                        <Col span="6"
                            ><div
                                class="normal-table table-content table-border-right table-border-bottom"
                            >
                                {{
                                    Math.exp(
                                        form.CompLeakProb.arm_leak_probs[4][0]
                                    ).toFixed(10)
                                }}
                            </div></Col
                        >
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="setting"
            title="手动设置"
            @on-ok="submitSetting"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <div>
                <div align="center">
                    <Row>
                        <Col span="6"> </Col>
                        <Col span="6">
                            <div class="normal-table table-title">
                                <span>泄漏直径</span>
                            </div>
                        </Col>
                        <Col span="6">
                            <div
                                class="normal-table table-title"
                                style="border-right: 1px solid black"
                            >
                                <span>值</span>
                            </div>
                        </Col>

                        <Col span="6"> </Col>
                    </Row>
                    <Row>
                        <Col span="6"> </Col>
                        <Col span="6">
                            <div class="normal-table table-left">0.01%</div>
                        </Col>
                        <Col span="6">
                            <div
                                class="normal-table table-content"
                                style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                "
                            >
                                <Input
                                    v-model="
                                        form.Pipe_ManualLeak.rel_freq_000d01
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                />
                            </div>
                        </Col>

                        <Col span="6"> </Col>
                    </Row>
                    <Row>
                        <Col span="6"> </Col>
                        <Col span="6">
                            <div class="normal-table table-left">0.1%</div>
                        </Col>
                        <Col span="6">
                            <div
                                class="normal-table table-content"
                                style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                "
                            >
                                <Input
                                    v-model="
                                        form.Pipe_ManualLeak.rel_freq_000d10
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                />
                            </div>
                        </Col>

                        <Col span="6"> </Col>
                    </Row>
                    <Row>
                        <Col span="6"> </Col>
                        <Col span="6">
                            <div class="normal-table table-left">1%</div>
                        </Col>
                        <Col span="6">
                            <div
                                class="normal-table table-content"
                                style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                "
                            >
                                <Input
                                    v-model="
                                        form.Pipe_ManualLeak.rel_freq_001d00
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                />
                            </div>
                        </Col>

                        <Col span="6"> </Col>
                    </Row>
                    <Row>
                        <Col span="6"> </Col>
                        <Col span="6">
                            <div class="normal-table table-left">10%</div>
                        </Col>
                        <Col span="6">
                            <div
                                class="normal-table table-content"
                                style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                "
                            >
                                <Input
                                    v-model="
                                        form.Pipe_ManualLeak.rel_freq_010d00
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                />
                            </div>
                        </Col>

                        <Col span="6"> </Col>
                    </Row>
                    <Row>
                        <Col span="6"> </Col>
                        <Col span="6">
                            <div class="normal-table table-left">100%</div>
                        </Col>
                        <Col span="6">
                            <div
                                class="normal-table table-content"
                                style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                "
                            >
                                <Input
                                    v-model="
                                        form.Pipe_ManualLeak.rel_freq_100d00
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                />
                            </div>
                        </Col>

                        <Col span="6"> </Col>
                    </Row>
                    <Row>
                        <Col span="6"> </Col>
                        <Col span="6">
                            <div
                                class="normal-table table-left table-border-bottom"
                            >
                                100%（意外）
                            </div>
                        </Col>
                        <Col span="6">
                            <div
                                class="normal-table table-content table-border-bottom"
                                style="
                                    padding: 10px;
                                    border-right: 1px solid black;
                                "
                            >
                                <Input
                                    v-model="
                                        form.Pipe_ManualLeak
                                            .fueling_fail_freq_override
                                    "
                                    :border="false"
                                    placeholder="请输入µ值"
                                />
                            </div>
                        </Col>

                        <Col span="6"> </Col>
                    </Row>
                    <Row>
                        <Col span="6"> </Col>
                        <Col span="6">
                            <div
                                class="table-left"
                                style="
                                    border-left: 1px solid black;
                                    border-bottom: 1px solid black;
                                "
                            >
                                泄漏探测概率
                            </div>
                        </Col>
                        <Col span="6">
                            <div
                                style="
                                    text-align: center;
                                    padding: 10px;
                                    font-size: 16px;
                                    font-weight: 500;
                                    border-right: 1px solid black;
                                    border-bottom: 1px solid black;
                                    border-left: 1px solid black;
                                    height: 50px;
                                "
                            >
                                <Input
                                    v-model="
                                        form.Pipe_ManualLeak.detection_credit
                                    "
                                    :border="false"
                                />
                            </div>
                        </Col>
                        <Col span="6"> </Col>
                    </Row>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="ignition"
            title="点火概率"
            @on-ok="submitIgnition"
            :mask-closable="false"
            draggable
            sticky
            width="800"
        >
            <div>
                <el-select
                v-model="modelFire"
                placeholder="请选择模型"
                style="width: 100%;margin-bottom: 20px;"
                @change="chooseFireModel"
            >
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
                <Row v-if="false">
                    <Col span="8">
                        <Card>
                            <template #title> 点火阈值列表 (kg/s) </template>

                            <div
                                v-for="(item, index) in form.Ignition
                                    .ign_thresholds"
                                :key="index"
                            >
                                <Row>
                                    <Col span="18"
                                        ><Input
                                            :border="false"
                                            v-model="
                                                form.Ignition.ign_thresholds[
                                                    index
                                                ]
                                            "
                                            placeholder="请输入点火阈值"
                                        />
                                    </Col>
                                    <Col span="6">
                                        <Button
                                            @click="delete_ign(index)"
                                            type="text"
                                            >删除</Button
                                        ></Col
                                    >
                                </Row>
                            </div>

                            <Button
                                @click="add_ign_thresholds()"
                                long
                                type="success"
                                >添加阈值</Button
                            >
                        </Card></Col
                    >
                    <Col span="1"></Col>
                    <Col span="15"
                        ><Table
                            :columns="columnsIgn"
                            :data="dataIgn"
                            border
                        ></Table
                    ></Col>
                </Row>
            </div>
        </Modal>
        <Modal
            v-model="system_fire"
            title="喷射火"
            @on-ok="submitSetModel"
            :mask-closable="false"
            draggable
            sticky
            width="700"
            class-name="vertical-center-modal"
        >
            <Form :model="form" :label-width="100">
                <FormItem label="喷嘴模型">
                    <Select v-model="form.System.nozzle_model">
                        <Option value="yuce">Yuceil/Otugen</Option>
                        <Option value="birc">Birch</Option>
                        <Option value="bir2">Birch2</Option>
                        <Option value="ewan">Ewan/Moodie</Option>
                        <Option value="molk">Molkov</Option>
                    </Select>
                </FormItem>
                <FormItem label="probit模型">
                    <Select v-model="form.System.probit_thermal_id">
                        <Option value="eise">eise</Option>
                        <Option value="tsao">tsao</Option>
                        <Option value="tno">tno</Option>
                        <Option value="lees">lees</Option>
                    </Select>
                </FormItem>
                <FormItem label="暴露时间">
                    <Input v-model="form.System.exposure_time" type="number">
                        <template #append>s</template></Input
                    >
                </FormItem>
            </Form>
        </Modal>
        <Modal
            v-model="system_boom"
            title="爆炸"
            @on-ok="submitCapacityParam"
            :mask-closable="false"
            draggable
            sticky
            width="800"
        >
            <Form :model="form" label-position="right" :label-width="100">
                <FormItem label="超压概率模型">
                    <Select v-model="form.System.probit_rel_id">
                        <Option value="coll">coll</Option>
                        <Option value="leis">leis</Option>
                        <Option value="lhse">lhse</Option>
                        <Option value="head">head</Option>
                    </Select>
                </FormItem>
            </Form>
            <div>
                <Row>
                    <Col span="4"><div class="table-title">变量</div></Col>
                    <Col span="4"
                        ><div class="table-title">0.01% 泄漏口径</div></Col
                    >
                    <Col span="4"
                        ><div class="table-title">0.1% 泄漏口径</div></Col
                    >
                    <Col span="4"
                        ><div class="table-title">1.0% 泄漏口径</div></Col
                    >
                    <Col span="4"
                        ><div class="table-title">10% 泄漏口径</div></Col
                    >
                    <Col span="4"
                        ><div class="table-title">100% 泄漏口径</div></Col
                    >
                </Row>
                <Row style="padding: 10px; border-bottom: 1px solid black">
                    <Col span="4"
                        ><div class="table-title-left">超压值 (Pa)</div></Col
                    >
                    <Col span="4"
                        ><div>
                            <Input
                                v-model="form.Overpressure.peak_overp_list[0]"
                                placeholder="请输入相应值"
                                size="small"
                                :border="false"
                            /></div
                    ></Col>
                    <Col span="4"
                        ><div>
                            <Input
                                v-model="form.Overpressure.peak_overp_list[1]"
                                placeholder="请输入相应值"
                                size="small"
                                :border="false"
                            /></div
                    ></Col>
                    <Col span="4"
                        ><div>
                            <Input
                                v-model="form.Overpressure.peak_overp_list[2]"
                                placeholder="请输入相应值"
                                size="small"
                                :border="false"
                            /></div
                    ></Col>
                    <Col span="4"
                        ><div>
                            <Input
                                v-model="form.Overpressure.peak_overp_list[3]"
                                placeholder="请输入相应值"
                                size="small"
                                :border="false"
                            /></div
                    ></Col>
                    <Col span="4"
                        ><div>
                            <Input
                                v-model="form.Overpressure.peak_overp_list[4]"
                                placeholder="请输入相应值"
                                size="small"
                                :border="false"
                            /></div
                    ></Col>
                </Row>
                <Row style="padding: 10px; border-bottom: 1px solid black">
                    <Col span="4"
                        ><div class="table-title-left">冲量 (Pa·s)</div></Col
                    >
                    <Col span="4"
                        ><div>
                            <Input
                                v-model="
                                    form.Overpressure.overp_impulse_list[0]
                                "
                                placeholder="请输入相应值"
                                size="small"
                                :border="false"
                            /></div
                    ></Col>
                    <Col span="4"
                        ><div>
                            <Input
                                v-model="
                                    form.Overpressure.overp_impulse_list[1]
                                "
                                placeholder="请输入相应值"
                                size="small"
                                :border="false"
                            /></div
                    ></Col>
                    <Col span="4"
                        ><div>
                            <Input
                                v-model="
                                    form.Overpressure.overp_impulse_list[2]
                                "
                                placeholder="请输入相应值"
                                size="small"
                                :border="false"
                            /></div
                    ></Col>
                    <Col span="4"
                        ><div>
                            <Input
                                v-model="
                                    form.Overpressure.overp_impulse_list[3]
                                "
                                placeholder="请输入相应值"
                                size="small"
                                :border="false"
                            /></div
                    ></Col>
                    <Col span="4"
                        ><div>
                            <Input
                                v-model="
                                    form.Overpressure.overp_impulse_list[4]
                                "
                                placeholder="请输入相应值"
                                size="small"
                                :border="false"
                            /></div
                    ></Col>
                </Row>
            </div>
        </Modal>
        <Modal
            v-model="pipe_param"
            title="管道参数"
            @on-ok="subPipe"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <Form :model="form" label-position="right" :label-width="100">
                <FormItem label="流体相态">
                    <Select v-model="form.Fluid.rel_phase">
                        <Option value="none">气体</Option>
                        <Option value="gas">饱和气体</Option>
                        <Option value="liquid">饱和液体</Option>
                    </Select>
                </FormItem>
                <FormItem label="管道外径" type="number">
                    <Input v-model="form.Pipe_ManualLeak.pipe_outer_diam">
                        <template #append>m</template></Input
                    >
                </FormItem>
                <FormItem label="管壁厚度" type="number">
                    <Input v-model="form.Pipe_ManualLeak.pipe_thickness">
                        <template #append>m</template>
                    </Input>
                </FormItem>
                <FormItem label="流体压力" type="number">
                    <Input v-model="form.Fluid.rel_pres">
                        <template #append>Pa</template></Input
                    >
                </FormItem>
                <FormItem
                    label="流体温度"
                    v-if="form.Fluid.rel_phase === 'none'"
                    type="number"
                >
                    <Input v-model="form.Fluid.rel_temp">
                        <template #append>K</template>
                    </Input>
                </FormItem>
            </Form>
        </Modal>
        <Modal
            v-model="amb_param"
            title="环境参数"
            @on-ok="submitCapacityParam"
            :mask-closable="false"
            draggable
            sticky
            width="700"
        >
            <Form :model="form" label-position="right" :label-width="100">
                <FormItem label="环境温度" type="number">
                    <Input v-model="form.Fluid.amb_temp">
                        <template #append>K</template></Input
                    >
                </FormItem>
                <FormItem label="环境压力" type="number">
                    <Input v-model="form.Fluid.amb_pres"
                        ><template #append>Pa</template>
                    </Input>
                </FormItem>
            </Form>
        </Modal>
        <Modal
            v-model="XYZ"
            title="场景坐标设置"
            @on-ok="submitCapacityParam"
            :mask-closable="false"
            draggable
            sticky
            width="300"
        >
            <Form :model="form" label-position="right" :label-width="50">
                <FormItem label="长" type="number">
                    <Input v-model="form.Scene.X">
                        <template #append>m</template>
                    </Input>
                </FormItem>
                <FormItem label="宽" type="number">
                    <Input v-model="form.Scene.Y"
                        ><template #append>m</template>
                    </Input>
                </FormItem>
                <FormItem label="高" type="number">
                    <Input v-model="form.Scene.Z">
                        <template #append>m</template></Input
                    >
                </FormItem>
            </Form>
        </Modal>
        <Modal
            v-model="people_position"
            title="人员分布情况"
            @on-ok="submitCapacityParam"
            :mask-closable="false"
            draggable
            sticky
            width="1300"
        >
            <Form
                :model="form"
                label-position="right"
                :label-width="100"
                inline
            >
                <FormItem label="随机数种子" type="number">
                    <Input v-model="form.System.rand_seed"> </Input>
                </FormItem>
                <FormItem label="模型忽略半径" type="number">
                    <Input v-model="form.System.excl_radius">
                        <template #append>m</template>
                    </Input>
                </FormItem>
            </Form>
            <Table :columns="columnsPeople" :data="form.Position">
                <template #count="{ row, index }">
                    <Input
                        v-model="row.count"
                        @on-change="changeRow(row.count, 'count', index)"
                    />
                </template>
                <template #descrip="{ row, index }">
                    <Input
                        v-model="row.descrip"
                        @on-change="changeRow(row.descrip, 'descrip', index)"
                    />
                </template>
                <template #xdistr="{ row, index }">
                    <Select
                        v-model="row.xdistr"
                        :transfer="true"
                        @on-change="changeRow(row.xdistr, 'xdistr', index)"
                    >
                        <Option value="dete">固定值</Option>
                        <Option value="unif">平均分布</Option>
                        <Option value="norm">正态分布</Option>
                    </Select>
                </template>
                <template #xa="{ row, index }">
                    <Input
                        v-model="row.xa"
                        @on-change="changeRow(row.xa, 'xa', index)"
                    />
                </template>
                <template #xb="{ row, index }">
                    <Input
                        v-model="row.xb"
                        @on-change="changeRow(row.xb, 'xb', index)"
                    />
                </template>
                <template #ydistr="{ row, index }">
                    <Select
                        v-model="row.ydistr"
                        :transfer="true"
                        @on-change="changeRow(row.ydistr, 'ydistr', index)"
                    >
                        <Option value="dete">固定值</Option>
                        <Option value="unif">平均分布</Option>
                        <Option value="norm">正态分布</Option>
                    </Select>
                </template>
                <template #ya="{ row, index }">
                    <Input
                        v-model="row.ya"
                        @on-change="changeRow(row.ya, 'ya', index)"
                    />
                </template>
                <template #yb="{ row, index }">
                    <Input
                        v-model="row.yb"
                        @on-change="changeRow(row.yb, 'yb', index)"
                    />
                </template>
                <template #zdistr="{ row, index }">
                    <Select
                        v-model="row.zdistr"
                        :transfer="true"
                        @on-change="changeRow(row.zdistr, 'zdistr', index)"
                    >
                        <Option value="dete">固定值</Option>
                        <Option value="unif">平均分布</Option>
                        <Option value="norm">正态分布</Option>
                    </Select>
                </template>
                <template #za="{ row, index }">
                    <Input
                        v-model="row.za"
                        @on-change="changeRow(row.za, 'za', index)"
                    />
                </template>
                <template #zb="{ row, index }">
                    <Input
                        v-model="row.zb"
                        @on-change="changeRow(row.zb, 'zb', index)"
                    />
                </template>
                <template #hours="{ row, index }">
                    <Input
                        v-model="row.hours"
                        @on-change="changeRow(row.hours, 'hours', index)"
                    />
                </template>
                <template #action="{ row, index }">
                    <Button type="error" @click="handel(index)">删除</Button>
                </template>
            </Table>
            <Button style="width: 100%; margin: 12px" @click="addRow"
                >+添加</Button
            >
        </Modal>
    </div>
</template>
<script>
import global from "../assets/global.vue";
import { resolveComponent } from "vue";
import { ref } from "vue";
export default {
    name: "pllTree",
    data() {
        return {
            showFireList:false,
            modelFire: ref(""),
            options: [
                {
                    value: "dnv",
                    label: "Hyram模型",
                },
                {
                    value: "dsb",
                    label: "Hyex模型",
                }
            ],
            showFireTitle:false,
            AccidentVisible: false,
            columnsPeople: [
                {
                    title: "人员数量",
                    key: "count",
                    slot: "count",
                },
                {
                    title: "人员描述",
                    key: "descrip",
                    slot: "descrip",
                },
                {
                    title: "X轴分布类型",
                    key: "xdistr",
                    slot: "xdistr",
                },
                {
                    title: "分布系数A",
                    key: "xa",
                    slot: "xa",
                },
                {
                    title: "分布系数B",
                    key: "xb",
                    slot: "xb",
                },
                {
                    title: "Y轴分布类型",
                    key: "ydistr",
                    slot: "ydistr",
                },
                {
                    title: "分布系数A",
                    key: "ya",
                    slot: "ya",
                },
                {
                    title: "分布系数B",
                    key: "yb",
                    slot: "yb",
                },
                {
                    title: "Z轴分布类型",
                    key: "zdistr",
                    slot: "zdistr",
                },
                {
                    title: "分布系数A",
                    key: "za",
                    slot: "za",
                },
                {
                    title: "分布系数B",
                    key: "zb",
                    slot: "zb",
                },
                {
                    title: "每人每年暴露时间 (h) ",
                    key: "hours",
                    slot: "hours",
                },
                {
                    title: "操作",
                    key: "hours",
                    slot: "action",
                },
            ],
            dataPeople: [
                {
                    count: 9,
                    descrip: "人员组1",
                    xdistr: "dete",
                    zdistr: "dete",
                    ydistr: "unif",
                    xa: 1,
                    xb: 20,
                    za: 0,
                    zb: 0,
                    ya: 1,
                    yb: 12,
                    hours: 2000,
                },
            ],
            columnsIgn: [
                {
                    title: "泄漏流率  (kg/s)",
                    key: "ign_thresholds",
                },
                {
                    title: "立即点火概率",
                    key: "immed_ign_probs",
                },
                {
                    title: "延迟点火概率",
                    key: "delayed_ign_probs",
                },
            ],
            dataIgn: [
                {
                    ign_thresholds: "<0.125",
                    immed_ign_probs: 0.008,
                    delayed_ign_probs: 0.004,
                },
                {
                    ign_thresholds: "0.125~6.25",
                    immed_ign_probs: 0.053,
                    delayed_ign_probs: 0.027,
                },
                {
                    ign_thresholds: "≥6.25",
                    immed_ign_probs: 0.23,
                    delayed_ign_probs: 0.12,
                },
            ],
            ////////
            components: false,
            //组件泄漏概率
            compressors: false, //压缩机
            vessel: false, //储罐
            valves: false, //阀门
            instruments: false, //设备
            joints: false, //结合处
            hoses: false, //软管
            pipe_length: false, //管长
            filters: false, //过滤器
            flanges: false, //法兰
            exchangers: false, //热交换器
            vaporizers: false, //冷凝器
            arms: false, //装卸臂
            ////////
            setting: false, //手动设置
            ignition: false, //点火概率
            system_fire: false,
            system_boom: false,
            pipe_param: false,
            amb_param: false,
            XYZ: false,
            people_position: false,
            ////////
            form: global.pllJSON,
            //storeForm: global.pllTempJSON,
            dataTree: [
                {
                    render: (h, { root, node, data }) => {
                        return h("span", {}, [
                            h("span", [
                                h(resolveComponent("Icon"), {
                                    type: "logo-buffer",
                                    style: {
                                        marginRight: "4px",
                                    },
                                }),
                                h("span", data.title),
                            ]),
                        ]);
                    },
                    title: "概率",
                    expand: true,

                    children: [
                        {
                            title: "组件",
                            expand: true,
                            render: (h, { root, node, data }) => {
                                return h(
                                    "span",
                                    {
                                        onClick: () => (this.components = true),
                                    },
                                    [
                                        h("span", [
                                            h(resolveComponent("Icon"), {
                                                type: "ios-construct",
                                                style: {
                                                    marginRight: "4px",
                                                },
                                            }),
                                            h("span", data.title),
                                        ]),
                                    ]
                                );
                            },
                            children: [
                                {
                                    title: "压缩机",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.compressors = true),

                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_compressors +
                                                            "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "储罐",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.vessel = true),
                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_vessels + "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "阀门",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.valves = true),
                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_valves + "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "设备",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.instruments = true),
                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_instruments +
                                                            "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },

                                {
                                    title: "结合处",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.joints = true),
                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_joints + "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "软管",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.hoses = true),
                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_hoses + "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "管道",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.pipe_length = true),
                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .pipe_length + "米"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "过滤器",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.filters = true),

                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_filters + "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "法兰",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.flanges = true),

                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_flanges + "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "热交换器",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.exchangers = true),
                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_exchangers +
                                                            "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "冷凝器",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.vaporizers = true),
                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_vaporizers +
                                                            "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "装卸臂",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.arms = true),

                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_arms + "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "附件1",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.output = true),

                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_vessels + "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                                {
                                    title: "附件2",
                                    expand: true,
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "div",
                                            {
                                                onClick: () =>
                                                    (this.output = true),

                                                style: {
                                                    width: "125px",
                                                },
                                            },
                                            [
                                                h("span", [
                                                    h("Icon", {
                                                        props: {
                                                            type: "",
                                                        },
                                                        style: {
                                                            marginLeft: "8px",
                                                            marginRight: "4px",
                                                        },
                                                    }),
                                                    h("span", data.title),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                float: "right",
                                                                color: "grey",
                                                            },
                                                        },
                                                        this.form.Components
                                                            .num_vessels + "个"
                                                    ),
                                                ]),
                                            ]
                                        );
                                    },
                                },
                            ],
                        },
                        {
                            render: (h, { root, node, data }) => {
                                return h(
                                    "span",
                                    {
                                        onClick: () =>
                                            (this.AccidentVisible = true),
                                    },
                                    [
                                        h("span", [
                                            h(resolveComponent("Icon"), {
                                                type: "ios-color-fill",
                                                style: {
                                                    marginRight: "4px",
                                                },
                                            }),
                                            h("span", data.title),
                                        ]),
                                    ]
                                );
                            },
                            title: "全口径泄漏事件概率",
                            expand: true,
                        },
                        {
                            render: (h, { root, node, data }) => {
                                return h(
                                    "span",
                                    {
                                        onClick: () => (this.ignition = true),
                                    },
                                    [
                                        h("span", [
                                            h(resolveComponent("Icon"), {
                                                type: "ios-color-wand",
                                                style: {
                                                    marginRight: "4px",
                                                },
                                            }),
                                            h("span", data.title),
                                        ]),
                                    ]
                                );
                            },
                            title: "点火概率",
                            expand: true,
                        },
                        {
                            render: (h, { root, node, data }) => {
                                return h(
                                    "span",
                                    {
                                        onClick: () => (this.setting = true),
                                    },
                                    [
                                        h("span", [
                                            h(resolveComponent("Icon"), {
                                                type: "ios-build",
                                                style: {
                                                    marginRight: "4px",
                                                },
                                            }),
                                            h("span", data.title),
                                        ]),
                                    ]
                                );
                            },
                            title: "手动设置",
                            expand: true,
                        },
                    ],
                },
                {
                    render: (h, { root, node, data }) => {
                        return h("span", {}, [
                            h("span", [
                                h(resolveComponent("Icon"), {
                                    type: "md-cube",
                                    style: {
                                        marginRight: "4px",
                                    },
                                }),
                                h("span", data.title),
                            ]),
                        ]);
                    },
                    title: "物理参数",
                    expand: true,
                    children: [
                        {
                            title: "燃烧模型",
                            expand: true,
                            render: (h, { root, node, data }) => {
                                return h("span", {}, [
                                    h("span", [
                                        h(resolveComponent("Icon"), {
                                            type: "ios-flame",
                                            style: {
                                                marginRight: "4px",
                                            },
                                        }),
                                        h("span", data.title),
                                    ]),
                                ]);
                            },
                            children: [
                                {
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "span",
                                            {
                                                onClick: () =>
                                                    (this.system_fire = true),
                                            },
                                            [h("span", [h("span", data.title)])]
                                        );
                                    },
                                    title: "喷射火",
                                    expand: true,
                                },
                                {
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "span",
                                            {
                                                onClick: () =>
                                                    (this.system_boom = true),
                                            },
                                            [h("span", [h("span", data.title)])]
                                        );
                                    },
                                    title: "爆炸",
                                    expand: true,
                                },
                            ],
                        },
                        {
                            render: (h, { root, node, data }) => {
                                return h(
                                    "span",
                                    {
                                        onClick: () => (this.pipe_param = true),
                                    },
                                    [
                                        h("span", [
                                            h(resolveComponent("Icon"), {
                                                type: "md-git-commit",
                                                style: {
                                                    marginRight: "4px",
                                                },
                                            }),
                                            h("span", data.title),
                                        ]),
                                    ]
                                );
                            },
                            title: "管道参数",
                            expand: true,
                        },
                        {
                            render: (h, { root, node, data }) => {
                                return h(
                                    "span",
                                    {
                                        onClick: () => (this.amb_param = true),
                                    },
                                    [
                                        h("span", [
                                            h(resolveComponent("Icon"), {
                                                type: "md-globe",
                                                style: {
                                                    marginRight: "4px",
                                                },
                                            }),
                                            h("span", data.title),
                                        ]),
                                    ]
                                );
                            },
                            title: "环境参数",
                            expand: true,
                        },
                    ],
                },
                {
                    title: "人员信息",
                    expand: true,
                    render: (h, { root, node, data }) => {
                        return h("span", {}, [
                            h("span", [
                                h(resolveComponent("Icon"), {
                                    type: "md-contacts",
                                    style: {
                                        marginRight: "4px",
                                    },
                                }),
                                h("span", data.title),
                            ]),
                        ]);
                    },
                    children: [
                        {
                            render: (h, { root, node, data }) => {
                                return h(
                                    "span",
                                    {
                                        onClick: () =>
                                            (this.environmentParam = true),
                                    },
                                    [
                                        h("span", [
                                            h(resolveComponent("Icon"), {
                                                type: "logo-steam",
                                                style: {
                                                    marginRight: "4px",
                                                },
                                            }),
                                            h("span", data.title),
                                        ]),
                                    ]
                                );
                            },
                            title: "设施与人员",
                            expand: true,
                            children: [
                                {
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "span",
                                            {
                                                onClick: () =>
                                                    (this.XYZ = true),
                                            },
                                            [h("span", [h("span", data.title)])]
                                        );
                                    },
                                    title: "场景坐标设置",
                                    expand: true,
                                },
                                {
                                    render: (h, { root, node, data }) => {
                                        return h(
                                            "span",
                                            {
                                                onClick: () =>
                                                    (this.people_position = true),
                                            },
                                            [h("span", [h("span", data.title)])]
                                        );
                                    },
                                    title: "人员分布情况",
                                    expand: true,
                                },
                            ],
                        },
                    ],
                },
            ],
        };
    },
    created() {
        this.modelFire='Hyex模型'
        this.form.System.probit_rel_id = "head";
        this.form.System.rand_seed = 3632850;
        this.form.System.excl_radius = 0.01;

        global.pllTempJSON = this.form;
    },
    computed: {
        freq() {
            const { num_vehicles, daily_fuelings, vehicle_days } =
                this.form.Vehicles;
            return num_vehicles * daily_fuelings * vehicle_days;
        },
    },
    watch: {
        form: {
            handler(newVal, oldVal) {
                //console.log('newVal',newVal); // 1234
                //console.log('oldVal',oldVal); // 123
                global.pllTempJSON = newVal;
            },
        },
    },
    methods: {
        chooseFireModel(value){
            global.pllTempJSON.Ignition.method=value
            console.clear()
            //this.form.Ignition.method=value;
            if(value=='dsb'){
                this.showFireList=false
            }
            if(value=='dnv'){
                this.showFireList=true
            }
        },
        addRow() {
            const temp = {
                ...this.form.Position[this.form.Position.length - 1],
            };
            this.form.Position.push(temp);
            console.log(this.form.Position, 33333);
        },
        changeRow(val, key, index) {
            this.form.Position[index][key] = val;
            console.log(val, this.form.Position, 33333);
        },
        handel(index) {
            this.form.Position.splice(index, 1);
            console.log(index, 111);
        },
        subPipe() {
            this.pipe_param = false;
        },
        add_ign_thresholds() {
            let temp = null;
            this.form.Ignition.ign_thresholds.push(temp);
        },
        submitSetModel() {
            this.setModule = false;
        },
        submitComponents() {},
        submitSetting() {},
        submitIgnition() {},
        submitCapacityParam() {
            this.capacityParam = false;
        },
        submitOutput() {
            this.output = false;
        },
        submitEnvironmentParam() {
            this.environmentParam = false;
        },
    },
};
</script>
<style scoped>
.normal-table {
    border-top: 1px solid black;
    border-left: 1px solid black;
}
.table-title {
    font-size: 16px;
    font-weight: 600;
    color: black;
    text-align: center;
    padding: 10px;
    background-color: rgb(222, 222, 222);
}
.table-left {
    font-size: 15px;
    font-weight: 500;
    color: black;
    text-align: center;
    height: 50px;
    padding: 12.5px;
}
.table-content {
    font-size: 15px;
    font-weight: 300;
    color: black;
    text-align: center;
    height: 50px;
    padding: 12.5px;
}
.table-border-bottom {
    border-bottom: 1px solid black;
}
.table-border-right {
    border-right: 1px solid black;
}
.table-title-left {
    font-size: 14px;
    font-weight: 500;
    color: black;
    text-align: center;
}
</style>