<template>
    <Layout style="height: 100%">
        <Sider class="sider-tree" hide-trigger>
            <plumeTree ref="treeRef"></plumeTree>
        </Sider>
        <Layout>
            <Header class="tool-bar">
                <Button type="success" @click="run()" shape="circle">
                    <svg class="icon" aria-hidden="true" style="width: 20px">
                        <use xlink:href="#icon-run-solid"></use>
                    </svg>
                    运行
                </Button>

                

                <FooterMsg></FooterMsg>
            </Header>
            <Content class="content" style="transform: translateX(0)">
                <!-- <Image
                v-if="!imgSRC"
                    :width="imgWidth"
                    :height="imgHeight"
                    :src="require('@/assets/default-bg.png')"
                    class="img"
                >
                </Image>
                <img
                    v-else
                    :src="imgSRC"
                    class="img"
                /> -->
                <Image
                  v-if="!viewVisible"
                      width="60%"
                      :height="imgHeight"
                      :src="require('@/assets/default-bg.png')"
                      class="img"
                  />
                <Modal
                    :transfer="false"
                    :footer-hide="true"
                    :fullscreen="true"
                    v-model="viewVisible"
                    title="运行结果"
                >
                <Tabs value="name1"  style="height: 100%">
                  <TabPane label="浓度视图" name="name0">
                    <Image
                        width="100%"
                        height="auto"
                        :src="imgSRC"
                        class="img"
                    >
                    </Image>
                  </TabPane>
                  <TabPane label="浓度数据" name="name1">
                    <div class="data" v-if="mole_fracs.length">
                            <Table
                            align="center"
                            border
                            :columns="mole_fracs_col"
                            :data="mole_fracs"
                        ></Table>
                          
                        </div>
                        <Image width="60%" v-else :src="require('@/assets/default-bg.png')" style="display:block;margin:auto"/>
                  </TabPane>
                </Tabs>
              </Modal>
            </Content>
        </Layout>
    </Layout>
    <Spin size="large" fix :show="spinShow"></Spin>
</template>
<script>
import FooterMsg from "../components/footer.vue";
import plumeTree from "../components/plumeComponent.vue";
import global from "../assets/global.vue";
import http from "../assets/axiosInstance";

export default {
    data() {
        return {
            response: null,
            imgSRC: '',
            imgHeight: "auto",
            imgWidth: "60%",
            spinShow: false,
            viewVisible:false,
            mole_fracs:[],
            mole_fracs_col:[
              {
                    title: "x (m)",
                    key: "x",
                },
                {
                    title: "y (m)",
                    key: "y",
                },
                {
                    title: "摩尔浓度",
                    key: "mole_fracs",
                },
            ]
        };
    },
    created(){
        // http({
        //         url: "/checkToken",
        //         method: "post",
        //         data: {
        //             token:localStorage.getItem('token'),
        //             username:localStorage.getItem('name')
        //         },
        //     })
        //         .then((res) => {
        //             if(!res.data.available){
        //                 localStorage.clear()
        //                 this.$router.push('/login')
        //             }
        //         })
        //         .catch(function (error) {});
    },
    components: { plumeTree, FooterMsg },
    methods: {
        dataCheck(obj){
            let keyNameArr=Object.keys(obj)
            for(let i=0;i<keyNameArr.length;i++){
                //console.log(typeof obj[keyNameArr[i]])
                if (
                    keyNameArr[i]  == "fluidPhase" ||
                    keyNameArr[i] == "nozzleModel"
                ){
                    console.log("羽流分布无需修正数据",keyNameArr[i],typeof obj[keyNameArr[i]])
                    continue
                }
                obj[keyNameArr[i]]=parseFloat(obj[keyNameArr[i]])
                console.log("羽流分布数据修正",keyNameArr[i],typeof obj[keyNameArr[i]])
            }
        },
        async run() {
          const {fluidPhase,fluid_pressure,points}=this.$refs.treeRef.form
          if(['gas','liquid'].includes(fluidPhase)&&(Number(fluid_pressure)>1296400||Number(fluid_pressure)<101325)){
               this.$message({
                message:'流体压力输入值应在101325Pa——1296400Pa之间',
                showClose:true,
                type:'error',
                duration:0
              })
             
              this.$nextTick(()=>{
                this.capacityParam=true
              })
              return false
            }
            this.dataCheck(global.plumeTempJSON)
            this.spinShow = true;
            const params =  global.plumeTempJSON
            params.points=points
            const points1=points.reduce((pre,item)=>{
              const {x,y}=item
              pre.push([x,y])
              return pre
            },[])
            http({
                url: "/c_sat/ppc/plume_dispersion",
                method: "post",
                data:{...params,points:points1}
            })
                .then((res) => {
                    if (res.data) {
                        this.$Notice.success({
                            title: "羽流分布计算成功！",
                        });
                        this.response = res;
                        this.imgHeight = "100%";
                        this.imgWidth = "100%";
                        this.imgSRC =
                            "/api" + res.data.plot;
                            this.mole_fracs=res.data.mole_fracs.reduce((pre,mole_fracs,index)=>{
                              const {x,y}=points[index]
                              pre.push({x,y,mole_fracs})
                              return pre
                            },[])
                            this.viewVisible=true
                    }
                    if (!res.data) {
                        this.$Notice.error({
                            title: "错误",
                            desc: "计算失败，请检查后端是否返回数据",
                        });
                    }
                    this.spinShow = false;
                })
                .catch(function (error) {
                    alert(error);
                    this.spinShow = false;
                });
        },
        save() {
            console.log("保存参数plume", global.plumeTempJSON);
        },
    },
};
</script>
<style scoped>
.sider-tree {
    background: #fff;
    border: 1px solid #dcdee2;
    border-radius: 5px;
    margin: 15px 0 15px 15px;
    overflow-y: auto;
}

.tool-bar {
    background-color: #fff;
    margin: 15px;
    border-radius: 5px;
    border: 1px solid #dcdee2;
}

.content {
    margin: 0 15px 15px 15px;
    border: 1px solid #dcdee2;
    border-radius: 5px;
    background-color: #fff;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img {
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    max-width: 100%;
    max-height: calc(100% - 10px);
}
</style>
