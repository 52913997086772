<template>
  <router-view v-slot="{ Component }">
    <keep-alive exclude="About">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>
<script>
import {checkToken} from './utils/checkToken'
export default {
    data() {
        return {};
    },
    created(){
        const quotes = [
            `“纵使神明在此，我亦会胜他半子” ― 海拥`,
            `“努力是为了站在万人中央，成为别人的光，加油” ― 海拥`,
            `“双手合十成为自己的神，自己所信念的就是信仰” - 海拥`,
            `“飞机飞行时所需动力只需要起飞时的十分之一不到，加油” ― 海拥`,
            `“无论你成为什么样的人，无论你把自己变成什么样，那都是你一直以来的样子” - 塔拉·韦斯托弗`,
            `“通常，当您认为自己处于某件事的尽头时，实际上您正处于另一件事的开始。” ― 弗雷德·罗杰斯`,
        ];

        let randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
        let title="欢迎进入 C-SAT 控制台"
        let saying="2023.6.14 终于把控制台清理干净了。\namostang23@gmail.com 这是我的邮箱，不知道哪位幸运儿继续开发这个项目，要是感兴趣的话可以找我聊聊"
        console.log(
            `%c ${title}`,
            "font-size:30px;padding:10px;font-weight:600;color:#2775b6;"
        );
        console.log(
            `%c🎉 ${randomQuote}`,
            "font-size:20px; color:#2376b7;padding:10px; margin:20px;border: 3px solid #2775b6;border-radius:10px;"
        );
        console.log(
            `%c🫨 ${saying}`,
            "font-size:14px;margin:10px"
        );
        checkToken()
    },
    onLaunch: function () {
        //监听页面跳转变化
        let that = this;
        uni.addInterceptor("navigateTo", {
            //监听跳转
            success(e) {
                console.log(e, "ddddd");
            },
        });
    },
};
</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.ivu-input-group-append{
  width: 80px!important;
}

.ivu-tabs{
  height: 100%;
}

.ivu-tabs-content {
  height: calc(100% - 52px);
}

.ivu-tabs-tabpane{
  overflow: auto;
}
</style>
