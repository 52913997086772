<template>
  <Layout style="height: 100%">
    <Sider class="sider-tree" hide-trigger>
      <qraTree ref="qraTreeRef"></qraTree>
    </Sider>
    <Layout>
      <!-- top 操作 -->
      <Header class="tool-bar">
        <Button type="success" @click="run" shape="circle">
          <svg class="icon" aria-hidden="true" style="width: 20px">
            <use xlink:href="#icon-run-solid"></use>
          </svg>
          运行
        </Button>
        <Divider type="vertical" />
        <Button @click="addMap" type="warning" shape="circle">
          <svg class="icon" aria-hidden="true" style="width: 20px">
            <use xlink:href="#icon-daoru"></use>
          </svg>
          选择地图
        </Button>
        <Divider type="vertical" />
        <Button @click="toogleMark" type="warning" shape="circle">
          <svg class="icon" aria-hidden="true" style="width: 20px">
            <use xlink:href="#icon-daoru"></use>
          </svg>
          {{ isBlab ? '结束标记' : '标记位置' }}
        </Button>
        <Divider type="vertical" />
        <Button @click="
          isBlab = false;
        isDensity = true;
        $Message.success('可框选密度');
        " type="warning" shape="circle">
          <svg class="icon" aria-hidden="true" style="width: 20px">
            <use xlink:href="#icon-daoru"></use>
          </svg>
          选择密度
        </Button>
        <FooterMsg></FooterMsg>
      </Header>
      <Content class="content" id="box">
        <div v-show="src" class="main-window" style="overflow-y: scroll">
          <div class="button-group">
            <div>
              <Button type="text" @click="resetRect()">重新绘制</Button>
            </div>
            <div>
              <Button type="text">设置</Button>
            </div>
          </div>
          <canvas id="mapCanvas" ref="mapCanvas" :style="{
            backgroundImage: `url(${!uploadMap ? src : null})`,
            height: canvasHeight + 'px',
          }" :width="canvasWidth" :height="canvasHeight" class="map-canvas" @mousedown="mousedown" @mouseup="mouseup"
            @click="click" @mousemove="mousemove">
            你的浏览器不支持 canvas，请升级你的浏览器。
            <!-- <img ref="map" :src=" mapImg " class="map-img"> -->
          </canvas>
        </div>
        <Image v-if="!src" width="60%" :src="require('@/assets/default-bg.png')"
          style="display:block;left: 50%;top: 50%;transform: translate(-50%, -50%);" />
        <Modal :transfer="false" :footer-hide="true" :fullscreen="true" v-model="viewVisible" title="运行结果">
          <Tabs :value="activeTab" style="height: 100%">
            <TabPane :label="item" v-for="item, index in tabList" :name="index" :key="item">
              <div>
                {{ (!index && (!qraParams.density || !qraParams.density.some(item => item))) ? '未传入人口密度，该图无数值' : '' }}
                {{ index == 2 ? '100%口径泄漏下，爆炸超压等值线' : '' }}
                {{ index == 3 ? '10%口径泄漏下，爆炸超压等值线' : '' }}
                {{ index == 4 ? '1%口径泄漏下，爆炸超压等值线' : '' }}
                {{ index == 5 ? '0.1%口径泄漏下，爆炸超压等值线' : '' }}
                {{ index == 6 ? '0.01%口径泄漏下，爆炸超压等值线' : '' }}
                {{ item[0] == '爆' ? '单位：Pa' : '' }}
                {{ index == 7 ? '100%口径泄漏下，喷射火热辐射等值线' : '' }}
                {{ index == 8 ? '10%口径泄漏下，喷射火热辐射等值线' : '' }}
                {{ index == 9 ? '1%口径泄漏下，喷射火热辐射等值线' : '' }}
                {{ index == 10 ? '0.1%口径泄漏下，喷射火热辐射等值线' : '' }}
                {{ index == 11 ? '0.01%口径泄漏下，喷射火热辐射等值线' : '' }}
                {{ item[0] == '热' ? '单位：kW/m²' : '' }}
                {{ item[0] == '个' ? '个人风险结果 单位：次/年' : '' }}
              </div>

              <img v-if="resPic[index]" :src="'/api/db/output/' +
                resPic[index] +
                '.png'
                " style="max-width: 100%;max-height: calc(100% - 30px);display:block;margin:auto" />
            </TabPane>
          </Tabs>
        </Modal>
      </Content>
    </Layout>
  </Layout>
  <Spin size="large" fix :show="spinShow"></Spin>
  <Modal v-model="uploadMap" title="GIS地图" :mask-closable="false" :closable="true" draggable sticky width="700">
    <Upload multiple type="drag" :show-upload-list="false" action="/api/c_sat/csat/upload"
      :on-success="uploadSuccess">
      <div style="padding: 20px 0">
        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
        <p>点击上传地图截图</p>
      </div>
    </Upload>
    <img style="width: 100%; height: 500px; object-fit: cover" ref="uploadImgViewRef" v-if="this.src" :src="this.src"
      alt="" srcset="" />
    <div style="margin-bottom: 20px">
      真实宽度（米）：
      <Input size="large" placeholder="请输入该图片对应的真实宽度" style="width: 81%" v-model="trueWidth" />
    </div>
    <div>
      网格宽度（米）：
      <Input size="large" placeholder="请输入网格对应的真实宽度" style="width: 81%" v-model="latticeSize" />
    </div>
    <template #footer>
      <Button type="primary" size="large" long @click="submitMap">确定</Button>
    </template>
  </Modal>
  <PointModal ref="pointModalRef" @submit="pointEditCb" />
</template>
<script>
import FooterMsg from "../components/footer.vue";
import qraTree from "../components/qraTree.vue";
import PointModal from "../components/PointModal.vue"
import http from "../assets/axiosInstance";
import { Button, Image } from "view-ui-plus";
import { bus } from "../main";
import PointModalVue from '@/components/PointModal.vue';
const drawDensity = (densityX, densityY, densityW, densityH) => {
  return (ctx) => {
    ctx.fillStyle = "#fff";
    ctx.strokeRect(densityX, densityY, densityW, densityH);
    ctx.fillRect(densityX, densityY, densityW, densityH);
  };
};
let intersectionMap = {};
let intersection = [];
let drawDensityFun = [];
let drawLeakPointFun = null;
const color1 = "rgba(255,0,0,0.5)"; //网格线颜色
const color2 = "#000"; //框选颜色
export default {
  data() {
    return {
      viewVisible: false,
      // 配置项数据
      qraParams: {
        width: 0,
        height: 0,
        pointX: 0,
        pointZ: 0,
        meterPerPx: 0,
        cellSize: 0,
        density: null,
      },
      point: {},
      // 泄露点的集合
      pointList: [],
      activePoint: null,
      // 结果
      viewImg: {},
      // 泄漏点网格序号
      pointXy: null,
      debounce: null,
      // 鼠标开始结束位置
      mouseXy: {
        startX: 0,
        endX: 0,
        startY: 0,
        endY: 0,
      },
      //
      // canvas的宽度
      canvasHeight: sessionStorage.getItem("content-height") - 20,
      canvasWidth: sessionStorage.getItem("content-width") - 20,
      spinShow: false, //加载
      response: null,
      mapHeight: sessionStorage.getItem("content-height") - 20 + "px",
      mapWidth_no_px: sessionStorage.getItem("content-width") - 20 || 0,
      mapHeight_no_px: sessionStorage.getItem("content-height") - 20,
      uploadIconHeight:
        (sessionStorage.getItem("content-height") - 20 - 88) / 2 + "px",
      mapImg: "http://c-sat.amostang.ltd/map.png",
      ///////画矩形
      flag: false,
      rectWidth: 0, //矩形框的宽
      rectHeight: 0, //矩形框的高
      totalRect: [], //画的所有的矩形坐标长度数据存储在数组中
      downX: 0, //鼠标点击图片落下时的位置（X）
      downY: 0,
      ctx: "", //dom节点
      canvas: null,
      rectTag: false,
      repeat: false,
      spinShow: false,
      //////
      trueWidth: null, //真实宽度
      uploadMap: false,
      delta: 0,
      chequerSize: 0,
      xCount: 0,
      yCount: 0,
      isBlab: false,
      isDensity: false,
      src: "",
      latticeSize: "",
      resPic: [],
      runed: false,
      tabList: [
        '社会风险',
        '个人风险',
        // '爆炸超压 100%',
        // '爆炸超压 10%',
        // '爆炸超压 1%',
        // '爆炸超压 0.1%',
        // '爆炸超压 0.01%',
        // '热辐射 100%',
        // '热辐射 10%',
        // '热辐射 1%',
        // '热辐射 0.1%',
        // '热辐射 0.01%',
      ]
    };
  },
  mounted() {
    let canvas = document.getElementById("mapCanvas");
    this.canvas = canvas;
    let ctx = canvas.getContext("2d");
    this.ctx = ctx;
    setTimeout(() => {
      this.mapWidth_no_px = this.canvas.offsetWidth;
      this.mapHeight_no_px = this.canvas.offsetHeight;
    });
    bus.off("message");
    bus.off("density");
    bus.on("message", ({ trueWidth, latticeSize }) => {
      this.trueWidth = trueWidth;
      this.latticeSize = latticeSize;
      // TODO
      this.qraParams.meterPerPx = this.trueWidth / this.qraParams.width;
      this.qraParams.cellSize = Number(this.latticeSize);
      intersectionMap = {};
      intersection = [];
      drawDensityFun = [];
      drawLeakPointFun = null;
      this.totalRect = [];
      this.qraParams.density = null;
      this.drawDensityFun = [];
      // this.point.pointX = 0;
      // this.point.pointZ = 0;
      //清空
      bus.emit("personnel", []);
      this.myMounted();
    });
    bus.on("density", (e) => {
      const item = drawDensityFun.find((item) => {
        return item.id === e.id;
      });
      item.personNum = e.personNum
      item.calculate.forEach((i) => {
        this.qraParams.density[i - 1] = e.density;
      });
    });
    window.addEventListener("resize", () => {
      this.debounce && clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (!this.qraParams.width || !this.$refs.mapCanvas) return;
        this.setCanvasStyle();
        this.$nextTick(() => {
          this.myMounted();
          this.resizePoint()
        });
      }, 500);
    });
  },
  created() {
    if (!sessionStorage.getItem("rectWidth")) {
      sessionStorage.setItem("downX", "0");
      sessionStorage.setItem("downY", "0");
      sessionStorage.setItem("rectWidth", "0");
      sessionStorage.setItem("rectHeight", "0");
    }
    if (
      sessionStorage.getItem("mapSrc") &&
      sessionStorage.getItem("mapWidth")
    ) {
      this.uploadMap = false;
    }
  },

  components: { Button, Image, qraTree, FooterMsg, PointModal },
  methods: {
    // 点击标记点位
    toogleMark() {
      this.isBlab = !this.isBlab;
      this.isDensity = false;//结束框选密度
      this.$Message.success(this.isBlab ? '可标记泄漏位置' : '结束泄露位置标记');
    },
    pointEditCb(form) {
      this.activePoint.pointForm = form
      this.pointList.forEach(item => {
        item.pointForm.YAvrg = form.YAvrg
      })
    },
    // 初始画布，完成网格
    myMounted() {
      this.clear();
      //画布尺寸米
      const xSize = this.trueWidth;
      //格子大小
      const latticeSize = this.latticeSize;
      //一个像素实际大小
      this.delta = xSize / this.canvasWidth;
      localStorage.setItem("realyWidth", this.qraParams.width);
      localStorage.setItem("xSize", xSize);
      localStorage.setItem(
        "delta",
        this.trueWidth / this.qraParams.width
      );
      localStorage.setItem("latticeSize", this.latticeSize);
      //一个格子的大小
      this.chequerSize = latticeSize / this.delta;
      //x轴需要多少个格子
      this.xCount = this.canvasWidth / this.chequerSize;
      //y轴需要多少个格子
      this.yCount = this.canvasHeight / this.chequerSize;
      !this.qraParams.density &&
        (this.qraParams.density = new Array(
          Math.ceil(this.xCount) * Math.ceil(this.yCount)
        ).fill(0));
      this.drawMap();
    },
    // 上传成功
    uploadSuccess(res, file) {
      this.src = res.url.replace("http://129.211.216.149:5002/", "https://processsafetytool.com/api/");
    },
    // 点击添加地图
    addMap() {
      this.uploadMap = true;
      this.clear();
    },
    submitMap() {
      this.viewVisible = false
      if (this.trueWidth) {
        sessionStorage.setItem("mapWidth", this.trueWidth);
        intersectionMap = {};
        intersection = [];
        drawDensityFun = [];
        drawLeakPointFun = null;
        //清空
        bus.emit("personnel", []);
        this.uploadMap = false;
        // 拼接数据
        this.qraParams.width = this.$refs.uploadImgViewRef.naturalWidth;
        this.qraParams.height =
          this.$refs.uploadImgViewRef.naturalHeight;
        this.qraParams.meterPerPx =
          this.trueWidth / this.qraParams.width;
        this.qraParams.cellSize = Number(this.latticeSize);
        this.qraParams.density = null;
        this.setCanvasStyle();
        this.clear();
        this.pointList = []
        this.$nextTick(() => {
          this.myMounted();
        });
        this.pointXy = null;
      }
    },
    // 设置canvas 高度
    setCanvasStyle() {
      if (!this.$refs.mapCanvas) return;
      this.canvasHeight =
        (this.$refs.mapCanvas.clientWidth * this.qraParams.height) /
        this.qraParams.width;
      this.canvasWidth = this.$refs.mapCanvas.clientWidth;
      this.$nextTick(() => {
        console.log(this.$refs.mapCanvas.clientWidth, "333333");
      });
    },
    dataCheck(data) {
      let titleBefore = "qra 参数修正";
      console.log(
        `%c 🔵 ${titleBefore}`,
        "font-size:16px;background-color:#c4cbcf;color:#2486b9;font-weight:600;padding:15px;margin:15px;border-radius:5px"
      );
      let obj = data;
      for (let key in obj) {
        //console.log(key,obj[key])
        if (key == "System") {
          this.dataSystemCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "Components") {
          this.dataComponentsCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "Scene") {
          this.dataSceneCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "Fluid") {
          this.dataFluidCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "Vehicles") {
          this.dataVehiclesCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "Pipe_ManualLeak") {
          this.dataPipe_ManualLeakCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "Position") {
          this.dataPositionCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "CompLeakProb") {
          this.dataCompLeakProbCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "Ignition") {
          this.dataIgnitionCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "Overpressure") {
          this.dataOverpressureCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "Accident") {
          this.dataAccidentCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
        if (key == "LocationDatas") {
          this.dataLocationDatasCheck(obj[key]);
          let title = key + " 修正完成";
          console.log(
            `%c ${title}`,
            "font-size:16px; color:#41ae3c;font-weight:600;"
          );
        }
      }
      let title = "全部数据修正成功，开始计算。";
      console.log(
        `%c 🟢 ${title}`,
        "font-size:16px;background-color:#eef7f2;color:#41ae3c;font-weight:600;padding:15px;margin:15px;border-radius:5px"
      );
    },
    dataLocationDatasCheck(obj) {
      for (let i = 0; i < obj.length; i++) {
        let component = obj[i].Components;
        let location = obj[i].Locations;
        for (let key in component) {
          console.log("dataLocation-Components数据修正:", key);
          component[key] = parseFloat(component[key]);
        }
        for (let key in location) {
          console.log("dataLocation-Locations数据修正:", key);
          location[key] = parseFloat(location[key]);
        }
      }
    },
    dataAccidentCheck(obj) {
      for (let key in obj) {
        if (
          key == "noz_po_dist" ||
          key == "noz_ftc_dist" ||
          key == "mvalve_ftc_dist" ||
          key == "svalve_ftc_dist" ||
          key == "svalve_ccf_dist" ||
          key == "overp_dist" ||
          key == "pvalve_fto_dist" ||
          key == "driveoff_dist" ||
          key == "coupling_ftc_dist"
        ) {
          continue;
        }
        obj[key] = parseFloat(obj[key]);
        console.log("Accident-数据修正:", key);
      }
    },
    dataIgnitionCheck(obj) {
      let keyNameArr = Object.keys(obj);
      for (let i = 0; i < keyNameArr.length; i++) {
        if (keyNameArr[i] == 'method') {
          continue
        }
        let firstArr = obj[keyNameArr[i]];
        for (let j = 0; j < firstArr.length; j++) {
          firstArr[j] = parseFloat(firstArr[j]);
        }
        console.log("Ignition-数据修正:", keyNameArr[i]);
      }
    },
    dataOverpressureCheck(obj) {
      let keyNameArr = Object.keys(obj);
      for (let i = 0; i < keyNameArr.length; i++) {
        let firstArr = obj[keyNameArr[i]];
        for (let j = 0; j < firstArr.length; j++) {
          firstArr[j] = parseFloat(firstArr[j]);
        }
        console.log("Overpressure-数据修正:", keyNameArr[i]);
      }
    },
    dataCompLeakProbCheck(obj) {
      let keyNameArr = Object.keys(obj);
      for (let i = 0; i < keyNameArr.length; i++) {
        let firstArr = obj[keyNameArr[i]];
        for (let j = 0; j < firstArr.length; j++) {
          let secondArr = firstArr[j];
          for (let k = 0; k < secondArr.length; k++) {
            secondArr[k] = parseFloat(secondArr[k]);
          }
        }
        console.log("CompLeakProb-数据修正:", keyNameArr[i]);
      }
    },
    dataPositionCheck(obj) {
      //console.log(obj.length)
      for (let i = 0; i < obj.length; i++) {
        for (let key in obj[i]) {
          if (
            key == "descrip" ||
            key == "xdistr" ||
            key == "ydistr" ||
            key == "zdistr"
          ) {
            continue;
          }
          obj[i][key] = parseFloat(obj[i][key]);
          console.log("Position", i, "-数据修正", key);
        }
      }
    },
    dataPipe_ManualLeakCheck(obj) {
      for (let key in obj) {
        obj[key] = parseFloat(obj[key]);
        console.log("Pipe_ManualLeak-数据修正:", key);
      }
    },
    dataFluidCheck(obj) {
      for (let key in obj) {
        if (key == "rel_phase") {
          console.log("Fluid-无需修正数据:", key);
          continue;
        }
        obj[key] = parseFloat(obj[key]);
        console.log("Fluid-数据修正:", key);
      }
    },
    dataSystemCheck(obj) {
      for (let key in obj) {
        if (
          key == "nozzle_model" ||
          key == "explosion_model" ||
          key == "probit_thermal_id" ||
          key == "probit_explosion_id" ||
          key == "YAvrg" ||
          key == "picURL" ||
          key == "density"
        ) {
          continue;
        }
        obj[key] = parseFloat(obj[key]);
        console.log("System-数据修正:", key);
      }
    },
    dataVehiclesCheck(obj) {
      for (let key in obj) {
        obj[key] = parseFloat(obj[key]);
        console.log("Vehicles-数据修正:", key);
      }
    },
    dataComponentsCheck(obj) {
      for (let key in obj) {
        obj[key] = parseFloat(obj[key]);
        console.log("Components-数据修正:", key);
      }
    },
    dataSceneCheck(obj) {
      for (let key in obj) {
        obj[key] = parseFloat(obj[key]);
        console.log("Scene-数据修正:", key);
      }
    },
    async run() {
      if (!this.src || !this.trueWidth || !this.latticeSize) {
        this.$message({
          message: '检查是否传入图片、是否指定网格大小寸及图片尺寸、是否标记泄漏点位置',
          showClose: true,
          type: 'error',
          duration: 5000
        })
        return false
      }
      const { rel_phase, rel_pres } = this.$refs.qraTreeRef.form.Fluid
      if (['gas', 'liquid'].includes(rel_phase) && (Number(rel_pres) > 1296400 || Number(rel_pres) < 101325)) {
        this.$message({
          message: '流体压力输入值应在101325Pa——1296400Pa之间',
          showClose: true,
          type: 'error',
          duration: 0
        })
        return false
      }
      const { pipe_outer_diam, pipe_thickness } = this.$refs.qraTreeRef.form.Pipe_ManualLeak
      if (pipe_outer_diam <= 2 * pipe_thickness) {
        // this.$Message.error('管道外径必须大于2倍管壁厚度')
        this.$message({
          message: '管道外径必须大于2倍管壁厚度',
          showClose: true,
          type: 'error',
          duration: 0
        })
        return false
      }
      // this.qraParams.picUrl=this.src
      // this.qraParams.pointX =
      //     (this.point.pointX * this.qraParams.width) / this.canvasWidth;
      // this.qraParams.pointZ =
      //     (this.point.pointZ * this.qraParams.height) / this.canvasHeight;
      this.spinShow = true
      const {
        Fluid,
        Vehicles,
        CompLeakProb,
        Pipe_ManualLeak,
        Ignition,
        Accident,
      } = this.$refs.qraTreeRef.form;

      const level = this.$refs.qraTreeRef.dzxData.level.split(' ').map(item => Number(item)).sort((a, b) => a - b)
      const rolevel = this.$refs.qraTreeRef.dzxData.rolevel.split(' ').map(item => Number(item)).sort((a, b) => a - b)
      const ovelevel = this.$refs.qraTreeRef.dzxData.ovelevel.split(' ').map(item => Number(item)).sort((a, b) => a - b)
      const LocationDatas = this.pointList.reduce((pre, item) => {
        const { pointX, pointY, pointForm } = item
        console.log(pointForm.Angle, pointForm, 'pointForm.Angle')
        const temp = {
          Components: this.$refs.qraTreeRef.mapValue3.Components,
          Locations: {
            XPosPixel: (pointX * this.qraParams.width) / this.canvasWidth,
            ZPosPixel: (pointY * this.qraParams.height) / this.canvasHeight,
            Angle: pointForm.Angle,
          },
        }
        for (let key in pointForm) {
          if (!['YAvrg', 'Angle', 'AngleTemp'].includes(key)) {
            temp.Components[key] = Number(pointForm[key])
          }
        }
        pre.push(temp)
        return pre
      }, [])
      const data = {
        System: {
          widthPixel: this.qraParams.width,
          heightPixel: this.qraParams.height,
          cellSizePixel:
            this.qraParams.cellSize / this.qraParams.meterPerPx,
          cellSizeRealMeter: this.qraParams.cellSize,
          YAvrg: this.pointList[0].pointForm.YAvrg, //目标高度、泄漏高度
          picURL: this.src,
          density: this.qraParams.density,
          ...this.$refs.qraTreeRef.form.System,
        },
        Fluid,
        Vehicles,
        CompLeakProb,
        Pipe_ManualLeak,
        Ignition,
        Accident,
        LocationDatas,
        // LocationDatas: [
        //     {
        //         Components: this.$refs.qraTreeRef.mapValue3.Components,
        //         Locations: {
        //             XPosPixel: this.qraParams.pointX,
        //             ZPosPixel: this.qraParams.pointZ,
        //             Angle: this.$refs.qraTreeRef.mapValue3.Angle,
        //         },
        //     },
        // ],
        level,
        rolevel,
        ovelevel
      };


      // Promise.all([this.getPll(data),this.getPllrop(data),this.getPllover(data)]).then(res=>{
      //   console.log(res,4444)
      // })
      this.dataCheck(data);
      http({
        url: "/c_sat/csat/pll",
        method: "post",
        data,
      })
        .then((res) => {
          if (!res.data) {
            this.$Notice.error({
              title: "错误",
              desc: "计算失败，请检查后端是否返回数据",
            });
            this.spinShow = false;
            //this.viewVisible = true;
          }
          if (!res.data.pic_counter) {
            this.$Notice.error({
              title: "错误",
              desc: "不合适的网格，建议在“地图尺寸”中，用户更改网格尺寸，如1 m改为1.1 m",
            });
            this.spinShow = false;
          }
          if (res.data) {
            this.$Notice.success({
              title: "个人风险与社会风险计算成功！",
            });
            console.log("pll返回值", res);
            // this.response = res;
            const { pic_people, pic_counter, ov_list, ro_list } = res.data;
            this.resPic = [pic_counter, pic_people, ...ov_list.map(item => item.PLLPlotPath), ...ro_list.map(item => item.PLLPlotPath)];
            this.spinShow = false;
            this.viewVisible = true;
            this.activeTab = 0
          }
        })
        .catch(function (error) {
          alert(error);
          this.spinShow = false;
        });
    },
    drawMap() {
      this.drawRect(
        0,
        0,
        this.canvas.offsetWidth,
        this.canvas.offsetHeight
      );
    },
    //获取右下角鼠标点击的最近的数值
    findNext(n, arr) {
      const map = {};
      const newArr = arr.map((i) => {
        map[Math.abs(i - n)] = i;
        return Math.abs(i - n);
      }); // Math绝对值方法,给出所有的差值
      const result = [];
      const min = Math.min(...newArr); // 寻找差值最小的数
      newArr.forEach((item) => {
        item === min && result.push(item); // 寻找差值最小的数对应的原数组元素
      });
      return map[Math.max(...result)];
    },
    click(e) {
      if (this.isBlab) {
        // this.pointXy = {
        const pointXy = {
          x: Math.floor(e.offsetX / this.chequerSize),
          y: Math.floor(e.offsetY / this.chequerSize),
        };
        this.addPoint(pointXy);
      }
      if (!this.isBlab && !this.flag) {
        // 点击
        const x = Math.floor(e.offsetX / this.chequerSize)
        const y = Math.floor(e.offsetY / this.chequerSize)
        this.activePoint = this.pointList.find(item => {
          return x === item.pointXy.x && y === item.pointXy.y
        })
        if (this.activePoint) {
          this.$refs.pointModalRef.init(this.activePoint.pointForm)
        }
      }
    },
    // 新增泄漏点
    addPoint(pointXy) {
      const { x, y } = pointXy
      const pointX = (x + 0.5) * this.chequerSize;
      const pointY = (y + 0.5) * this.chequerSize;
      this.pointList.push({
        pointX, pointY, x, y, pointForm: {
          pipe_length: 20,
          num_compressors: 0,
          num_vessels: 0,
          num_valves: 5,
          num_instruments: 3,
          num_joints: 35,
          num_hoses: 1,
          num_filters: 0,
          num_flanges: 0,
          num_exchangers: 0,
          num_vaporizers: 0,
          num_arms: 0,
          num_extra_comp1: 0,
          num_extra_comp2: 0,
          YAvrg: 1,
          Angle: 0,
          AngleTemp: 0,
        }, pointXy
      })
      this.drawLeakPoint(pointX, pointY)
    },
    // 绘制泄漏点
    // drawPoint(pointXy) {
    //     // this.clear();
    //     // this.drawMap();
    //     // drawDensityFun.length && this.callDrawDensityFun();
    //     // if (!this.pointXy) return;
    //     // console.log(this.chequerSize,'chequerSizechequerSize')
    //     // const currentX = (this.pointXy.x + 0.5) * this.chequerSize;
    //     // const currentY = (this.pointXy.y + 0.5) * this.chequerSize;
    //     const {x,y}=pointXy
    //     const pointX = (x + 0.5) * this.chequerSize;
    //     const pointY = (y + 0.5) * this.chequerSize;
    //     this.pointList.push({pointX,pointY,x,y,pointForm:{}})
    //     // drawLeakPointFun = drawLeakPoint(pointX, pointY);
    //     // drawLeakPointFun(this.ctx);
    //     this.drawLeakPoint(pointX,pointY)
    //     // bus.emit("shotpoint");
    // },
    // canvas绘制泄露点
    drawLeakPoint(x, y) {
      this.ctx.beginPath();
      this.ctx.arc(x, y, 6, 0, 2 * Math.PI);
      this.ctx.fillStyle = "#000";
      this.ctx.fill();
    },
    // 重置点位
    resizePoint() {
      this.callDrawDensityFun()
      this.pointList.forEach(item => {
        const { x, y } = item.pointXy
        item.pointX = (x + 0.5) * this.chequerSize;
        item.pointY = (y + 0.5) * this.chequerSize;
        this.drawLeakPoint(item.pointX, item.pointY)
      })
    },
    // 按下鼠标
    mousedown(e) {
      if (this.isBlab) return;
      if (this.isDensity) {
        this.flag = true;
        this.downX = e.offsetX; // 鼠标落下时的X
        this.downY = e.offsetY; // 鼠标落下时的Y
        this.mouseXy.startX = e.offsetX;
        this.mouseXy.startY = e.offsetY;
      }
    },
    // 移动鼠标
    mousemove(e) {
      if (this.isBlab) return;
      if (this.flag) {
        this.rectWidth = Math.abs(this.downX - e.offsetX);
        this.rectHeight = Math.abs(this.downY - e.offsetY);
        if (this.rectWidth < 10 || this.rectHeight < 10) {
          this.rectWidth = 0;
          this.rectHeight = 0;
          return;
        }
        this.clear();
        //绘制网格
        this.myMounted()
        this.resizePoint();
        //绘制圆点
        // drawLeakPointFun && drawLeakPointFun(this.ctx);
        //绘制泄漏点
        drawDensityFun.length && this.callDrawDensityFun();
        const x = parseFloat(this.downX);
        const y = parseFloat(this.downY);
        const lineW = parseFloat(Math.abs(this.downX - e.offsetX));
        const lineY = parseFloat(Math.abs(this.downY - e.offsetY));
        // 开始绘制;
        this.ctx.beginPath();
        // 设置线条颜色，必须放在绘制之前
        this.ctx.strokeStyle = color2;
        // 线宽设置，必须放在绘制之前
        this.ctx.lineWidth = 1;
        this.ctx.strokeRect(x, y, lineW, lineY);
      }
    },
    callDrawDensityFun() {
      drawDensityFun.forEach((item) => {
        item.ctxCb(this.ctx);
      });
    },
    calculatePoints(x, y, width, height, unit) {
      // console.log(intersectionMap, 'intersectionMap')
      const columns = width / unit; // 列数
      const rows = height / unit; // 行数
      const points = []; // 储存点坐标的数组
      // 循环计算每个点的坐标
      for (let row = y / unit; row < rows; row++) {
        for (let column = x / unit; column < columns; column++) {
          // const x1 = column * unit;
          // const y1 = row * unit;
          // const x2 = (column + 1) * unit;
          // const y2 = row * unit;
          // const x3 = (column + 1) * unit;
          // const y3 = (row + 1) * unit;
          const x4 = Math.round(column * unit);
          const y4 = Math.round((row + 1) * unit);
          // console.log(intersectionMap?.[`${x4}-${y4}`], x4, y4)
          // const x4 = column * unit;
          // const y4 = (row + 1) * unit;
          if (intersectionMap?.[`${x4}-${y4}`]?.isCover === true) {
            return true;
          }
          if (intersectionMap[`${x4}-${y4}`]) {
            intersectionMap[`${x4}-${y4}`].isCover = true;
          }
          points.push(intersectionMap[`${x4}-${y4}`]);
        }
      }
      // console.log(points)
      return points;
    },
    // 抬起鼠标
    mouseup(e) {
      if (this.isBlab) return;
      if (this.isDensity) {
        // 结束位置
        this.mouseXy.endX = e.offsetX;
        this.mouseXy.endY = e.offsetY;
        // 算出xy的格子开始和结束位置
        const { startX, startY, endX, endY } = this.mouseXy;
        const sX = Math.ceil(startX / this.chequerSize);
        const sY = Math.ceil(startY / this.chequerSize);
        const eX = Math.ceil(endX / this.chequerSize);
        const eY = Math.ceil(endY / this.chequerSize);
        // 算出上下左右四个矩形的位置
        const left = sX > eX ? eX : sX;
        const right = sX > eX ? sX : eX;
        const top = sY > eY ? eY : sY;
        const bottom = sY > eY ? sY : eY;
        const tempArr = [];
        const xCount = Math.ceil(this.xCount);
        const yCount = Math.ceil(this.yCount);
        for (let i = 1; i <= xCount * yCount; i++) {
          const row = Math.ceil(i / xCount); //行
          const col = i % xCount || xCount; //列
          // console.log(row,col,222)
          if (
            row >= top &&
            row <= bottom &&
            col >= left &&
            col <= right
          ) {
            tempArr.push(i);
          }
        }
        // console.log(left,right,top,bottom,33333)
        const sumArr = this.totalRect.reduce((pre, item) => {
          pre.push(...item);
          return pre;
        }, []);

        const filterArr = tempArr.filter(
          (item) => !sumArr.includes(item)
        );
        // 判断是否是已绘制的点位
        if (!filterArr.length) {
          this.$Message.error(`不能选择已选内容！`);
          this.flag = false;
          this.isDensity = false;
        } else {
          this.$Message.success(`选中${filterArr.length}个格子`);
          this.totalRect.push(filterArr);
          // console.log(this.totalRect,33333333)
          this.flag = false;
          this.isDensity = false;
          const densityX =
            this.chequerSize *
            ((filterArr[0] % xCount || xCount) - 1);
          const densityY =
            this.chequerSize *
            (Math.ceil(filterArr[0] / xCount) - 1);
          const densityW =
            this.chequerSize *
            (filterArr[filterArr.length - 1] % xCount ||
              xCount) -
            densityX;
          const densityH =
            this.chequerSize *
            Math.ceil(
              filterArr[filterArr.length - 1] / xCount
            ) -
            densityY;
          drawDensityFun.push({
            ctxCb: drawDensity(
              densityX,
              densityY,
              densityW,
              densityH
            ),
            sum: filterArr.length,
            personNum: 0,
            id: +new Date(),
            calculate: filterArr,
          });
          drawLeakPointFun && drawLeakPointFun(this.ctx);
          bus.emit("personnel", drawDensityFun);
        }
        this.clear();
        this.myMounted()
        this.resizePoint();
      }
    },
    //清除画布
    clear() {
      this.ctx.clearRect(0, 0, this.canvas.offsetWidth, this.canvas.offsetHeight);
    },
    // 绘制网格
    drawRect(x, y, lineW, lineY) {
      x = parseFloat(x);
      y = parseFloat(y);
      lineW = parseFloat(lineW);
      lineY = parseFloat(lineY);
      // 开始绘制;
      this.ctx.beginPath();
      // 设置线条颜色，必须放在绘制之前
      this.ctx.strokeStyle = color1;
      // 线宽设置，必须放在绘制之前
      this.ctx.lineWidth = 1;
      const xList = [];
      const yList = [];
      // 绘制网格1
      for (let i = 0; i < this.xCount; i++) {
        this.ctx.lineWidth = 1;
        xList.push(x + (i * lineW) / this.xCount);
        this.ctx.beginPath();
        this.ctx.strokeStyle = color1;
        this.ctx.moveTo(x + (i * lineW) / this.xCount, y + lineY);
        this.ctx.lineTo(x + (i * lineW) / this.xCount, y);
        this.ctx.stroke();
      }
      for (let i = 0; i < this.yCount; i++) {
        this.ctx.lineWidth = 1;
        yList.push(y + (i * lineY) / this.yCount);
        this.ctx.beginPath();
        this.ctx.strokeStyle = color1;
        this.ctx.moveTo(x + lineW, y + (i * lineY) / this.yCount);
        this.ctx.lineTo(x, y + (i * lineY) / this.yCount);
        this.ctx.stroke();
      }
      intersection = [];
      intersectionMap = {};
      if (!intersection.length) {
        //get坐标的交集
        intersection = yList.map((y) => {
          return xList.map((x) => {
            const x1 = Math.round(x);
            const y1 = Math.round(y);
            intersectionMap[`${x1}-${y1}`] = {
              x: x1,
              y: y1,
              density: 0,
            };
            return intersectionMap[`${x1}-${y1}`];
          });
        });
      }
    },
    // 清空画布
    resetRect() {
      this.$Modal.confirm({
        title: "警告",
        content: "此操作将清空画布，是否继续？",
        onOk: () => {
          intersectionMap = {};
          intersection = [];
          drawDensityFun = [];
          drawLeakPointFun = null;
          this.totalRect = [];
          this.qraParams.density = null;
          this.drawDensityFun = [];
          this.pointList = []
          //清空
          bus.emit("personnel", []);
          this.myMounted();
          this.$Message["success"]({
            background: false,
            content: "画布重置成功！",
          });
        },
        onCancel: () => {
          this.$Message.info("操作取消");
        },
      });
    },
  },
};
</script>
<style scoped>
.sider-tree {
  background: #fff;
  border: 1px solid #dcdee2;
  border-radius: 5px;
  margin: 15px 0 15px 15px;
  overflow-y: auto;
}

.tool-bar {
  background-color: #fff;
  margin: 15px;
  border-radius: 5px;
  border: 1px solid #dcdee2;
}

.content {
  margin: 0 15px 15px 15px;
  border: 1px solid #dcdee2;
  border-radius: 5px;
  background-color: #fff;
  overflow-y: auto;
  transform: translateX(0);
}

.main-window {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.map-canvas {
  /* border-radius: 10px; */
  padding: 0;
}

.map-img {
  width: 500px;
  height: 100px;
  object-fit: contain;
}

.button-group {
  position: fixed;
  background-color: #ffffffa9;
  margin: 15px;
  right: 25px;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
}

#mapCanvas {
  /* background-color: #0e3300; */
  /* background-image: url("map.png"); */
  background-size: 100% 100%;
  width: 100%;
  /* width: 100%;
    height: 100%; */
}

::v-deep .ivu-tabs-content {
  height: calc(100% - 60px);

  .ivu-tabs-tabpane {
    overflow: auto;
  }
}
</style>
