<template>
    <Layout style="height: 100%">
        <Sider class="sider-tree" hide-trigger>
            <singlePoolFireTree></singlePoolFireTree>
        </Sider>
        <Layout>
            <Header class="tool-bar"
                ><Button type="success" @click="run()" shape="circle"
                    ><svg class="icon" aria-hidden="true" style="width: 20px">
                        <use xlink:href="#icon-run-solid"></use>
                    </svg>
                    运行
                </Button>
                <template v-if="gis.enable">
                  <ImgUpload ref="imgUploadRef" @uploadSuccess="uploadSuccess"/>
                  <Button
                    style="margin-left:16px"
                      @click="chosePoint=true;$Message.success('可标记泄漏位置');"
                      type="warning"
                      shape="circle"
                  >
                      <svg class="icon" aria-hidden="true" style="width: 20px">
                          <use xlink:href="#icon-daoru"></use>
                      </svg>
                      标记位置
                  </Button>
                </template>
                <Button type="success" @click="changeEnalbe()" shape="circle" style="margin-top:20px;float:right"
                    >{{gis.enable?'关闭':'启用'}}GIS</Button>
                <FooterMsg></FooterMsg>
            </Header>
            <Content class="content">
                <Tabs v-if="dataSinglePool.length">
                    <TabPane label="FERC" name="name0">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="img_ferc"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                    <TabPane label="LNGFIRE" name="name1">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="img_lngfire"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                    <TabPane label="MONTOIR" name="name2">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="img_montoir"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                    <TabPane label="SANDIA" name="name3">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="img_sandia"
                            class="img"
                        >
                        </Image>
                    </TabPane>

                    <TabPane label="相关数据" name="name5">
                        <div class="single-pool-table">
                            <Table
                                align="center"
                                border
                                :columns="columnsSinglePool"
                                :data="dataSinglePool"
                            ></Table>
                        </div>
                    </TabPane>
                    <TabPane label="GIS" v-if="gis.enable" name="10">
                        <Image
                            :width="imgWidth"
                            :height="imgHeight"
                            :src="gisImagePath"
                            class="img"
                        >
                        </Image>
                    </TabPane>
                </Tabs>

                <template v-else>
                  <div v-if="gis.imageurl" ref="gisBox" class="gis-box" @click="gisClick">
                    <img ref="gisImgRef" :src="gis.imageurl" class="gis-img">
                    <div :style="pointStyle" class="gis-po"></div>
                  </div>
                  <Image v-else width="60%"  :src="require('@/assets/default-bg.png')" style="display:block;left: 50%;top: 50%;transform: translate(-50%, -50%);"/>
                </template>  
            </Content>
        </Layout>
    </Layout>
    <Spin size="large" fix :show="spinShow"> </Spin>
</template>
<script>
import ImgUpload from "../components/imgUpload.vue"
import FooterMsg from "../components/footer.vue";
import singlePoolFireTree from "../components/singlePoolFireComponent.vue";
import global from "../assets/global.vue";
import http from "../assets/axiosInstance";
export default {
    data() {
        return {
            spinShow: false,
            response: null,
            imgWidth: "60%",
            imgHeight: "auto",
            img_ferc: "",
            img_lngfire: "",
            img_montoir: "",
            img_sandia: "",
            columnsSinglePool: [
                {
                    title: "FERC",
                    key: "FERC",
                },
                {
                    title: "FERC Side",
                    key: "FERC_Side",
                },
                {
                    title: "LNGFIRE3",
                    key: "LNGFIRE3",
                },
                {
                    title: "LNGFIRE3 Side",
                    key: "LNGFIRE3_Side",
                },
                {
                    title: "Montoir",
                    key: "Montoir",
                },
                {
                    title: "Montoir_Side",
                    key: "Montoir_Side",
                },
                {
                    title: "Sandia",
                    key: "Sandia",
                },
                {
                    title: "Sandia_Side",
                    key: "Sandia_Side",
                },
                {
                    title: "演算距离",
                    key: "TestPointList",
                },
            ],
            dataSinglePool: [],
            enable:false,//是否启用gis
            gis:{
              enable:false,
              imageurl:'',
              meterPerPixel:0,
              centerX:0,
              centerY:0,
              rotation:0,
            },
            chosePoint:false,
            pointStyle:{
              left:0,
              top:0,
            },
            gisImagePath:'',
        };
    },
    created() {
        // http({
        //     url: "/checkToken",
        //     method: "post",
        //     data: {
        //         token: localStorage.getItem("token"),
        //         username: localStorage.getItem("name"),
        //     },
        // })
        //     .then((res) => {
        //         if (!res.data.available) {
        //             localStorage.clear();
        //             this.$router.push("/login");
        //         }
        //     })
        //     .catch(function (error) {});
    },
    components: { singlePoolFireTree, FooterMsg,ImgUpload },
    methods: {
      gisClick(e){
          if(this.chosePoint){
            const {layerX,layerY}=e
            const y=(layerY/this.$refs.gisBox.offsetHeight)
            const x= (layerX/this.$refs.gisBox.offsetWidth)
            this.pointStyle={
              left:x*100+'%',
              top:y*100+'100%'
            }
            const {naturalWidth,naturalHeight}=this.$refs.gisImgRef
            this.gis.centerX=naturalWidth*x
            this.gis.centerY=naturalHeight*y
            this.chosePoint=false
          }
        },
        changeEnalbe(){
          this.gis.enable=!this.gis.enable
          this.gis.imageurl='',
          this.gis.meterPerPixel=0
          this.gis.centerX=0
          this.gis.centerY=0
          this.gis.rotation=0
          if(this.gis.enable){
            this.img_flux_plot_filepath=require('@/assets/default-bg.png')
            this.img_temp_plot_filepath=require('@/assets/default-bg.png')
            this.runed=false
            this.$nextTick(()=>{
              this.$refs.imgUploadRef.init()
            })
          }
        },
        uploadSuccess(data){
          const {src:imageurl,rotate,meterPerPixel}=data
          Object.assign(this.gis,{imageurl,rotate,meterPerPixel})
        },
        dataCheck(obj) {
            let keyNameArr = Object.keys(obj);

            for (let i = 0; i < keyNameArr.length; i++) {
                if (
                    keyNameArr[i] == "TestPointList" ||
                    keyNameArr[i] == "InsolationType" ||
                    keyNameArr[i] == "PoolShape" ||
                    keyNameArr[i] == "TestFluxList" ||
                    keyNameArr[i] == "TestPointList"
                ) {
                    console.log(
                        "单池火无需修正数据",
                        keyNameArr[i],
                        typeof obj[keyNameArr[i]]
                    );
                    continue;
                }
                obj[keyNameArr[i]] = parseFloat(obj[keyNameArr[i]]);
                console.log(
                    "单池火数据修正",
                    keyNameArr[i],
                    typeof obj[keyNameArr[i]]
                );
            }
        },
        async run() {
            this.dataCheck(global.singlePoolFireTempJSON);
            this.spinShow = true;
            //console.log("运行参数pll", global.pllTempJSON);
            http({
                url: "/c_sat/csat/singlePoolFire",
                method: "post",
                // data: global.singlePoolFireTempJSON,
                data: {...global.singlePoolFireTempJSON,gis:this.gis},
            })
                .then((res) => {
                    if (res.data) {
                        this.dataSinglePool = [];
                        this.$Notice.success({
                            title: "单池火计算成功！",
                        });
                        this.response = res;
                        this.img_ferc = "/"+
                            res.data.Method_FERC_PlotPath;
                        this.img_lngfire =
                            "/" +
                            res.data.Method_LNGFIRE3_PlotPath;
                        this.img_montoir = "/"+res.data.Method_Montoir_PlotPath;
                        this.img_sandia = "/"+res.data.Method_Sandia_PlotPath;
                        this.gisImagePath =
                            "/api" +
                            res.data.gis_plot_filepath;
                        this.handleSinglePoolData(res.data);
                    }
                    if (!res.data) {
                        this.$Notice.error({
                            title: "错误",
                            desc: "计算失败，请检查后端是否返回数据",
                        });
                    }
                    this.spinShow = false;
                })
                .catch(function (error) {
                    alert(1);
                });
        },
        handleSinglePoolData(res) {
            let arr_length = res.Method_FERC.length;
            for (let i = 0; i < arr_length; i++) {
                let arrNode = {
                    FERC: res.Method_FERC[i].toFixed(2),
                    FERC_Side: res.Method_FERC_Side[i].toFixed(2),
                    LNGFIRE3: res.Method_LNGFIRE3[i].toFixed(2),
                    LNGFIRE3_Side: res.Method_LNGFIRE3_Side[i].toFixed(2),
                    Montoir: res.Method_Montoir[i].toFixed(2),
                    Montoir_Side: res.Method_Montoir_Side[i].toFixed(2),
                    Sandia: res.Method_Sandia[i].toFixed(2),
                    Sandia_Side: res.Method_Sandia_Side[i].toFixed(2),
                };
                this.dataSinglePool.push(arrNode);
            }
        },
        save() {
            console.log("保存参数pll", global.pllTempJSON);
        },
    },
};
</script>
<style scoped>
.sider-tree {
    background: #fff;
    border: 1px solid #dcdee2;
    border-radius: 5px;
    margin: 15px 0 15px 15px;
    overflow-y: auto;
}
.tool-bar {
    background-color: #fff;
    margin: 15px;
    border-radius: 5px;
    border: 1px solid #dcdee2;
}
.content {
    margin: 0 15px 15px 15px;
    border: 1px solid #dcdee2;
    border-radius: 5px;
    background-color: #fff;
    overflow-y: auto;
}
.single-pool-table {
    padding: 20px;
    text-align: center;
    width: 100%;
}
/* .img {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */

.gis-img{
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.gis-box{
  width: fit-content;
  margin: auto;
  position:relative
}
.gis-po{
  position:absolute ;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #000;
}
</style>
