<template>
  <span class="home-btn" @click="backHome">返回主页</span>
</template>
<script setup>
import {ref} from 'vue'

const backHome=()=>{
  window.location.href = "http://processsafetytool.com/";
}
</script>
<style>
.home-btn{
  position: fixed;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  top: 40px;
  right: 60px;
}
</style>