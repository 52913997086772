import {createApp} from 'vue'
import ElementPlus from 'element-plus'

import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import 'element-plus/dist/index.css'
import axios from './assets/axiosInstance.js'
import mitt from 'mitt';
//进度条
import NProgress from 'nprogress'
// import 'nprogress/nprogress.css';
export const bus = mitt();
//全局进度条的配置 
NProgress.configure({
    easing: 'ease', // 动画方式 
    speed: 1000, // 递增进度条的速度 
    showSpinner: false, // 进度环显示隐藏
    trickleSpeed: 200, // 自动递增间隔 
    minimum: 0.9, // 更改启动时使用的最小百分比 
    parent: 'body', //指定进度条的父容器 
})

const app = createApp(App)


axios.interceptors.request.use(config => {
    // 每次切换页面时，调用进度条
    NProgress.start();
    //return不可省略
    return config
})

//response中完成进度条NProgress.done()
axios.interceptors.response.use(config => {
    //服务响应时完成进度条
    NProgress.done()
    return config
})


app.use(router)
    .use(ViewUIPlus)
    .use(ElementPlus)
    .mount('#app')

app.config.globalProperties.$axios = axios;