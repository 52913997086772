<template>
  <div>
    <Modal
        v-model="createDashboard"
        title="请选择计算模型"
        :closable="false"
        :mask-closable="false"
        width="700px"
    >
      <div align="center">
        <Row>
          <Col span="8">
            <div
                tabindex="1"
                class="button-main"
                @click="chooseSubmit('pll')"
            >
              <div class="font-icon">
                <svg class="icon" aria-hidden="true">
                  <use
                      xlink:href="#icon-fengxianzhenduan"
                  ></use>
                </svg>
              </div>
              PLL计算
            </div>
          </Col>
          <Col span="8">
            <div
                tabindex="1"
                class="button-main"
                @click="chooseSubmit('plume')"
            >
              <div class="font-icon">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-yangan"></use>
                </svg>
              </div>
              羽流分布
            </div>
          </Col>
          <Col span="8">
            <div
                tabindex="1"
                class="button-main"
                @click="chooseSubmit('accumulation')"
            >
              <div class="font-icon">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-tianqi_icon"></use>
                </svg>
              </div>
              累积效应分布
            </div>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <div
                tabindex="1"
                class="button-main"
                @click="chooseSubmit('jetflame')"
            >
              <div class="font-icon">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-huozaishigu"></use>
                </svg>
              </div>
              喷射火热辐射
            </div>
          </Col>
          <Col span="8">
            <div
                tabindex="1"
                class="button-main"
                @click="chooseSubmit('overpressure')"
            >
              <div class="font-icon">💥</div>
              爆炸超压
            </div>
          </Col>
          <Col span="8">
            <div
                tabindex="1"
                class="button-main"
                @click="chooseSubmit('single-pool-fire')"
            >
              <div class="font-icon">🔥</div>
              池火热辐射（甲烷）
            </div>
          </Col>
        </Row>
        <div>
          <div
              tabindex="1"
              align="center"
              class="button-main"
              @click="chooseSubmit('qra')"
          >
            <div class="font-icon">🗺️</div>
            个人风险和社会风险计算
          </div>
        </div>
      </div>



    </Modal>
  </div>

</template>

<script>
import global from '../assets/global.vue'
export default {
  name: "Guide",
  data() {
    return {
      createDashboard:false,
    };
  },
  created(){
    if(!sessionStorage.getItem('router')){
      //this.createDashboard=true
    }
  },
  methods: {
    chooseSubmit(router){
      this.$router.push(router);
      sessionStorage.setItem('router',router)
      global.router=router
      this.createDashboard=false
    }
  },
};
</script>
<style scoped>
.button-main {
  height: 150px;
  font-size: 18px;
  font-weight: 400;
  transition: border-bottom 0.65s, background-color 0.65s, color 1s,
  font-size 0.65s, font-weight 0.65s, box-shadow 0.65s;
  padding: 10px;
}

.button-main:hover {
  background-color: rgb(242, 250, 254);
  border-bottom: 10px solid #2d8cf0;
  border-radius: 10px;
  cursor: pointer;
  color: black;
  font-size: 20px;
  font-weight: 400;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.button-main:focus {
  border-bottom: 10px solid rgb(143, 193, 250);
  color: white;
  font-weight: 900;
  background-color: #2d8cf0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.font-icon {
  font-size: 50px;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>